import React, { useState, useEffect, useCallback } from "react";
import useAuth from "../hooks/useAuth";
import api from "../utils/api";
import NotificationSection from "../components/NotificationSection";
import LoanDetails from "../components/LoanDetailsCard/Index";
// import CreditScore from '../components/CreditScoreCard/Index';
import SurveyCard from "../components/SurveyCard/Index";
import MonthlyGoalCard from "../components/MonthlyGoalCard/Index";
import QuizCard from "../components/QuizCard/Index";
import CashBackCard from "../components/CashbackCard/Index";
import GoalAssessment from "../components/GoalsAssessment/Index";
import WellnessScoreCard from "../components/WellnessScoreCard/Index";
// import UtilizationCard from '../components/UtilizationCard/Index';
import FicoCard from "../components/FicoCard/Index";
import MonthlySpendMxCard from "../components/MonthlySpendMxCard/MonthlySpendMxCard";
import PulseWidget from "../components/TopMerchant/TopMerchant";
// import WeekendSummary from "../components/WeekendSummary/WeekendSummary";
// import OnboardingModal from '../components/OnboardingModal/Index';
import cookies from '../utils/cookies';

// import Form from 'react-bootstrap/Form';
// import insuranceImage from '../assets/insurance.jpg';
import TransactionsMxCard from "../components/TransactionsMxCard/transactionsCard";
import AccountsMxCard from "../components/AccountsMxCard/accountsCard";
import BudgetsMxCard from "../components/BudgetsMxCard/budgetsCard";

import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import MxConnectModal from "../components/MxSettings/MxConnectModal";
import ExternalMxSettings from "../components/ExternalMxSettings/MxConnectModal";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import ToggleButton from 'react-bootstrap/ToggleButton';


// import Modal from 'react-bootstrap/Modal';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  thumbEmojis,
  feelingEmojis,
  feelingReasons,
} from "../assets/emojiSVG/emojis";
import {
  formatDateForDisplay,
  findDaysFromToday,
  createDueDateMsg,
  formatDecimals,
  // calcCreditChange,
  prevMonthDate,
  // startOfMonthWithAddDays,
  // calculateAge,
  //monthDates,
  //monthPaymentStatus,
  // findLastMonthsPayments,
  // parseIdFromLink,
} from "../utils/helpers";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import { MOOD_QUESTION, DEBT_UTILIZATION_THRESHOLD } from "../utils/constants";

import isToday from "date-fns/isToday";
import isAfter from "date-fns/isAfter";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isThisMonth from "date-fns/isThisMonth";
import format from "date-fns/format";

const buildEmojiImages = (svgArray, options) => {
  const entries = Object.entries(svgArray).sort(
    (x, y) => x[1].rank - y[1].rank
  );
  const imgList = entries.map(([key, value]) => {
    const keyWithUpper = key.charAt(0).toUpperCase() + key.slice(1);
    return options?.withTitle ? (
      <>
        <img
          className="iconImg"
          src={svgArray[key].img}
          alt={key}
          style={{ marginRight: 0 }}
        />
        <p className="feelingText">{keyWithUpper}</p>
      </>
    ) : (
      <img
        className="iconImg"
        src={svgArray[key].img}
        alt={key}
        style={{ marginRight: 0 }}
      />
    );
    //[] Had trouble getting selector to work in scss file
  });

  return imgList;
};

const emojiiOptions = buildEmojiImages(feelingEmojis);
const reasonOptions = buildEmojiImages(feelingReasons, { withTitle: true });
const inspireOptions = buildEmojiImages(thumbEmojis);
MOOD_QUESTION.main.options = emojiiOptions;
MOOD_QUESTION.followUp.options = reasonOptions;

function ClientView({ overrideUserID }) {
  const isMobileApp = localStorage.getItem('platform').split(',')[0];


  // const handleSubmit = (e) => {

  //   const userID = overrideUserID || auth?.user?.id;
  // try {
  //   let data = {
  //     enrolled: document.getElementById("enrolled").value,
  //     paymentFrequency: document.getElementById("paymentFrequency").value,
  //     enrollmentDate: document.getElementById("enrollmentDate").value,
  //   }
  //   alert(JSON.stringify(data));
  //  api.insuranceEnrollmentPut(userID, data)         
  //   .then(resp => resp?.data)
  //   .then(res => {
  //     if (res.status === 200) {
  //       setInsuranceEnrollment(res?.data ? res?.data : {})
  //       setInsuranceEnrollmentMsg("Enrollment success!");

  //       setTimeout(() => {
  //         setInsuranceEnrollmentMsg(null);
  //       }, 2000);


  //     }
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // } catch (error) {
  //   console.log(error)
  //   alert("cache error")
  // }


  // };
  // const [radioValue, setRadioValue] = useState('1');
  // const [show, setShow] = useState(false);

  // const handleClose = () => {
  //   setShow(false)


  // };
  // const handleShow = () => setShow(true);

  // function showModalPopUp(value) {
  //   if (value === '2') {
  //     handleShow(true);
  //   } else {
  //     enrollInsurance();
  //   }
  //   setRadioValue(value);
  // }

  // const radios = [
  //   { name: 'Activate', value: '1' },
  //   { name: 'De-Activate', value: '2' }
  // ];

  const [showMxModal, setShowMxModal] = useState(false);
  const [mxDataList, setMxDataList] = useState(null);
  // const [insuranceEnrollment, setInsuranceEnrollment] = useState(null);
  // const [insuranceEnrollmentMsg, setInsuranceEnrollmentMsg] = useState(null);



  const auth = useAuth();
  const userID = overrideUserID || auth?.user?.id;

  const role = auth?.user?.role;
  const isAdmin = role === 'ROLE_SUPER_ADMIN';


  const overrideUserLink =
    overrideUserID &&
    `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}/users/${overrideUserID}`;
  const userIDLink = overrideUserLink || auth?.user?.link;

  const [todayInspireMe, setTodaysInspireMe] = useState(null);
  const [finData, setFinData] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [onboardingComplete, setOnboardingComplete] = useState(true); // Default with true to prevent 'flicker' after login
  const [isFirstAssessment, setIsFirstAssessment] = useState(false);
  const [showAssessment, setShowAssessment] = useState(false);
  const [isCurrAssessmentComplete, setCurrAssessmentComplete] = useState(false);
  const [refreshUserData, setRefreshUserData] = useState(0);

  //======================================Build Notification Functions
  const removeNotificationFromState = useCallback(
    (data) => {
      //Matches body of the notification to the state array and filters it out
      //[] Better to have an ID?
      setNotifications((prev) => {
        const newNotify = prev.filter((x) => x.body !== data.body);
        return newNotify;
      });
      //Update Local storeage that it was dismissed...
      /* Remember Dismissed notification
      Credit score changes for the month
      Quiz Challange for the month
      Payments for the day (should be new msg tomorrow or gone)
      */
      const parsedType = data.type.includes("credit") ? "credit" : data.type;
      switch (parsedType) {
        case "credit":
          localStorage.setItem(`U${userID}-creditNotifyDismissed`, cookies.getDate());
          break;
        case "pay":
          localStorage.setItem(`U$${userID}-payNotifyDismissed`, cookies.getDate());
          break;
        case "quiz":
          localStorage.setItem(`U${userID}-quizNotifyDismissed`, cookies.getDate());
          break;
        default:
      }
    },
    [userID]
  );

  const paymentNotificaiton = useCallback(
    (finData) => {
      const lastDismissed = localStorage.getItem(
        `U$${userID}-payNotifyDismissed`
      );
      const isDismissed = isToday(cookies.getDateByInput(lastDismissed));
      if (isDismissed) return null;
      // const dueDate = new Date('30 April 2022');
      const dueDate = finData.loan.nextPaymentDate;
      const daysToPayment = findDaysFromToday(dueDate);
      //Only return notificaiton if due date is "upcoming" and within 5 days of the due date
      if (daysToPayment > 5 || daysToPayment < 0) return null;
      const message = createDueDateMsg(daysToPayment);
      return {
        type: "pay",
        body: message,
        dismissAction: removeNotificationFromState,
      };
    },
    [userID, removeNotificationFromState]
  );

  // const creditScoreNotification = useCallback(
  //   (finData) => {
  //     const lastDismissed = localStorage.getItem(`U${userID}-creditNotifyDismissed`);
  //     const isDismissed = isThisMonth(new Date(lastDismissed));
  //     if (isDismissed) return null;

  //     const change = finData.creditScore.change;
  //     if (!change) return;
  //     let message =
  //       change > 0
  //         ? `Your credit score improved by ${change} pts`
  //         : `Your credit score decreased by ${change / -1} pts`; // Divide by -1 to make a posative integer for display
  //     return change > 0
  //       ? { type: 'creditUp', body: message, dismissAction: removeNotificationFromState }
  //       : { type: 'creditDown', body: message, dismissAction: removeNotificationFromState };
  //   },
  //   [userID, removeNotificationFromState]
  // );
  // const processServerNotifications = useCallback((svrNotifications) => {
  //   const notificaitonObjects = svrNotifications.map((n) => {
  //     if (n.type === 'WARNING_CLAWBACK') {
  //       return { type: 'quiz', body: "WARNING: CLAWBACK", dismissAction: removeNotificationFromState };
  //     }
  //   });
  //   return notificaitonObjects;
  // }, []);

  //Quizzes - User answers are below... may need to drill "notification Update" to the quiz module to pass up isComplete...
  const buildQuizNotification = useCallback(() => {
    const lastDismissed = localStorage.getItem(
      `U${userID}-quizNotifyDismissed`
    );
    const isDismissed = isThisMonth(new Date(lastDismissed));
    if (isDismissed) return null;

    const currMonth = format(cookies.getDate(), "MMMM");
    const message = `You completed the ${currMonth} quiz challenge!`;
    return {
      type: "quiz",
      body: message,
      dismissAction: removeNotificationFromState,
    };
  }, [userID, removeNotificationFromState]);

  // const updateCBHistoryState = (assessment) => {
  /* update state when assessment is completed 
 Unable to complete until cbhistory api gives correct data 
  */
  // };

  //======================================================================= useEffects > Init & W. user ID
  useEffect(() => {
    //Init - get today's inspire data
    let mounted = true;
    const getLatestInspiration = async () => {
      const res = await api.getLatestInspiration();

      const allInspirations = res.data._embedded.inspirations;
      const sorted = allInspirations.sort((y, x) =>
        x?.availableDate.localeCompare(y?.availableDate)
      );
      console.log((sorted[0]));
      const prompt = sorted[0]?.url;
      const inspID = sorted[0]?._links.self.href;
      if (mounted) {
        setTodaysInspireMe({
          main: {
            question: "Was this helpful?",
            options: inspireOptions,
            image: prompt,
            inspID,
            availableDate: sorted[0]?.availableDate,
          },
        });
      }
    };
    try {
      getLatestInspiration();
    } catch (e) {
      console.log("Inspiration Error", e);
    }
    return () => {
      mounted = false;
    };
  }, []);
  useEffect(() => {
    const checkOnboarding = async () => {
      const userData = await api.getUserData(userID);
      const isComplete = userData.data.completeOnBoard;
      setOnboardingComplete(isComplete);
      // setIsFirstAssessment(!isComplete);
      //Assumes on boarding and first occur @ the same time.  This can break if the onboard is interrupted...
    };
    checkOnboarding();
  }, []);
  useEffect(() => {


    const listMXConnectionStatusesByAccount = async () => {
      const mxData = await api.listMXConnectionStatusesByAccount(userID);
      // alert(JSON.stringify(mxData?.data));
      setMxDataList(mxData?.data ? mxData?.data : []);
    };

    listMXConnectionStatusesByAccount();



    // const insuranceEnrollmentCall = async () => {
    //   const resp = await api.insuranceEnrollment(userID);
    //   if (resp.status === 200) {
    //     setInsuranceEnrollment(resp?.data ? resp?.data : {});
    //     if (resp?.data?.enrolled) {
    //       if (resp?.data.enrolled) {
    //         // setRadioValue('1');
    //       } else {
    //         // setRadioValue('2');
    //       }
    //     }
    //   }
    // };
    // if (isAdmin) {

    //   insuranceEnrollmentCall();
    // }
  }, [userID, isAdmin]);


  useEffect(() => {
    let mounted = true;
    const getUsersFinData = async () => {
      //[] API is supposed to start @ begining of loan api will use 01jan2022 unless specified
      try {
        const res = await Promise.allSettled([
          api.getUserNotifications(userIDLink),
          api.getCBHistory(userID),
          api.getUserData(userID),
          api.getUsersGoalStatusForMonth(userID),
          api.getUsersLatestFinData(userIDLink),
          api.getUsersCBRecords(userID),
          api.getUserTlLoan(userID),
          api.getUsersWellnessScores(userID),
          api.getUsersFICOScores(userID),
        ]);
        const [
          rawSvrNotifications,
          rawCBHistory,
          rawUserData,
          rawMonthStatus,
          latestFinData,
          rawCBRecords,
          rawLoanInfo,
          rawWellnessScores,
          rawFICOScores,
        ] = res;

        const svrNotifications =
          rawSvrNotifications.value?.data._embedded.notifications;
        const userData = rawUserData.value?.data;
        const currMonthGoals = rawMonthStatus.value?.data;
        const cbRecordDollars =
          rawCBRecords.value?.data._embedded.cashbackRecords.map(
            (x) => x.cashbackAmount
          ); //$values of cb earned for the user, not reconciled with any month
        const cbRecords = rawCBRecords.value?.data._embedded.cashbackRecords;
        const totalCBEarned = cbRecordDollars
          .reduce((a, b) => a + b, 0)
          ?.toFixed(2);
        const userCBHistory = rawCBHistory.value?.data
          ? Object.entries(rawCBHistory.value?.data)
            .map(([assessmentDate, v]) => {
              console.log(assessmentDate, cbRecords);
              const monthsAssessment = {
                assessmentMonth: assessmentDate,
                noNewDebt: v.noNewDebt,
                paidBillsOnTime: v.paidBillsOnTime,
                paidParachuteOnTime: v.paidParachuteOnTime,
                missedAndLatePayments: v.missedAndLatePayments,
                underUtilization:
                  v.underUtilization === "NaN" ? null : v.underUtilization,
                utilization: v.utilizationAtEndOfMonth,
                cbRecordDollarsEarned: cbRecords?.find((x) => {
                  // return formatDateForDisplay(x.appliedDate) === formatDateForDisplay(assessmentDate);
                  console.log(x.appliedDate.slice(0, 7), assessmentDate.slice(0, 7));
                  return (
                    x.appliedDate.slice(0, 7) === assessmentDate.slice(0, 7)
                  );
                  /* Slice is to strip out timezone and date, IOT accomodate the server entries for 00:00Z, but all other dates are EST */
                  // return isSameMonth(new Date(x.appliedDate), new Date(assessmentDate));
                })?.cashbackAmount,
              };
              return monthsAssessment;
            })
            .sort((y, x) =>
              x.assessmentMonth.localeCompare(y.assessmentMonth)
            )
          : []; //Latest first
        const loanInfo = rawLoanInfo.value?.data;
        const latest = latestFinData.value.currMonth;
        const latestWellnessScores =
          rawWellnessScores.value?.data?._embedded?.wellnessScores
            ?.sort((y, x) => x.scoreDate?.localeCompare(y.scoreDate))
            ?.slice(0, 2);
        /* Get latest 2 wellnessScores in order */
        const latestFICOScore =
          rawFICOScores?.value.data._embedded.fICOScores?.sort((y, x) =>
            x?.pulledDate.localeCompare(y?.pulledDate)
          )?.[0];
        /* ================================================================= */
        // const changeInCredit = calcCreditChange(currMonth, lastMonthEnd); //Ideally should be server side

        const isValidDebtInfo = (input) => {
          if (input === "Infinity") return false;
          if (!isNaN(input)) return true;
          return false;
        };
        const credit = {
          utilizationPercent: isValidDebtInfo(latest?.creditUtilization)
            ? latest?.creditUtilization
            : null, //Decimal value [] Change var name to reflect that
          totalCredit: isValidDebtInfo(latest?.totalCreditAvailable)
            ? latest?.totalCreditAvailable?.toFixed(2)
            : null,
          creditUtilizedDollars: isValidDebtInfo(latest?.utilizedCredit)
            ? latest?.utilizedCredit?.toFixed(2)
            : null,
          createDateTime: latest?.createDateTime,
        };

        console.log("CREATING STATE...");
        //=====================================================================================================
        /* Disable Utilization by
        finData.debt & utilization is short circuited
        findata.goalAssessmentData is forced false & short circuited 
        Re-Order client cards*/
        let tempDate = null;
        try {
          tempDate = loanInfo?.nextPaymentDate.split("T")[0];
        } catch (error) {
        }
        const newFinData = {
          //Format for appropriate Cards
          //Most data should be formatted and as strings for display
          loan: {
            /* Need to replace, need to search user loan info... */
            startDate: userData.loanStartDate, //Date loan was issued
            nextPaymentDate: tempDate || formatDateForDisplay(loanInfo?.nextPaymentDate),
            nextPaymentAmount: formatDecimals(loanInfo?.nextPaymentAmount),
            totalLoan: formatDecimals(loanInfo?.amount),
            // remainingBalance: formatDecimals(loanInfo?.outstandingBalance), // Hidden for launch, will redesign loan card fields
            currMonthDueDate: currMonthGoals?.currentMonthUpcomingPaymentDate,
            currentMonthPaymentStatus:
              currMonthGoals?.currentMonthUpcomingPaymentStatus, //Doesn't get used was only for ref
            isCurrMonthPaid:
              currMonthGoals?.currentMonthUpcomingPaymentStatus?.includes(
                "Paid"
              ), // based on PaidEarly, PaidOnTime,PaidLate (fr: tlSchedule > status)
          },
          debt: {
            utilizedAmount: null && credit.creditUtilizedDollars,
            total: null && credit.totalCredit, // $ Limit
            utilizationPercent: null && credit.utilizationPercent, //Decimal, convert to % for display
          },
          cashBack: {
            //[]TODO, need to add to front end or make separate call
            potential:
              userData.monthlyPotentialCashBack &&
              Number(userData.monthlyPotentialCashBack),
            cbHistory: userCBHistory,
            totalCbEarned: totalCBEarned, // [] Replaced with cbRecords/
            // userData.monthlyPotentialCashBack && monthsCBEarned * Number(userData.monthlyPotentialCashBack), //[] Will update from user
            totalPossibleCB:
              !isNaN(userData.maxCashback) && Number(userData.maxCashback), //[] Will update from user
            clawBack: null, //If notification of clawback(takes 1st) and puts history object in clawback, otherwise null
            clearClawbackFromState: () => {
              setFinData((p) => {
                return {
                  ...p,
                  cashBack: {
                    ...p.cashBack,
                    clawBack: null,
                  },
                };
              });
            },
          },
          goalAssessmentData: {
            //For monthly goal assessment at the start of each month, asking about the previous month
            rewardAmount:
              userData.monthlyPotentialCashBack &&
              Number(userData.monthlyPotentialCashBack),
            utilization: {
              goalMet: false, //userCBHistory[0]?.underUtilization, //[] Index 0 is last month b/c cbhistory is sorted above
              threshold: DEBT_UTILIZATION_THRESHOLD,
              lastMonthsUtilization:
                null && userCBHistory[0]?.utilizationAtEndOfMonth, //endMonth utiliztion
            },
            parachuteLoan: {
              goalMet: userCBHistory[0]?.paidParachuteOnTime, //lastMonthPayments?.every((p) => p.status === 'PaidOnTime' || p.status === 'PaidEarly'), //Were ALL payments made (boolean),
              missedAndLatePayments: userCBHistory[0]?.missedAndLatePayments, //lastMonthPayments?.filter((x) => x.status === 'PaidLate' || x.status === 'Missed'),
              // [] Need to when payment was received (late payments) for each one
            },
          },
          wellnessScore: {
            currentScore: latestWellnessScores?.[0]?.score,
            dateCalculated: latestWellnessScores?.[0]?.scoreDate,
            previousScore: latestWellnessScores?.[1]?.score,
            // userAge: calculateAge(new Date(`${userData?.dobDay} ${userData?.dobMonth} ${userData?.dobYear}`)),
            // userIncome: userData?.annualIncome,
          },
          fico: latestFICOScore,
          utilization: null && credit,
        };
        console.log("CHECKING CLAWBACK NOTIFICATIONS....");
        //check and add clawback to cashbackdata
        svrNotifications.forEach((n) => {
          if (n.type === "WARNING_CLAWBACK") {
            const notificationIDLink = n._links.self.href;
            const wasRead = n.shown;
            // match the notificaion applicable month with the cbHistory month for the month affected
            const notifyPeriod = n.applicableDate;
            const historyOfClawbackMonth = userCBHistory.find(
              (h) => h.assessmentMonth === notifyPeriod
            ); //assumes the history object was updated.
            newFinData.cashBack.clawBack = {
              ...historyOfClawbackMonth,
              notifyDetails: { notificationIDLink, wasRead },
            };
          }
        });

        // console.log('__newFinData', newFinData);
        //====================================
        //[] Calc and build notification=============
        const currentNotifications = [
          paymentNotificaiton(newFinData), //[] Verify  still correct  with new data source
          // creditScoreNotification(newFinData),
          // processServerNotifications(svrNotifications), // disabled b/c only svr notification so far is the clawback warning.
        ];
        const filteredNotificaitons = currentNotifications.filter((x) => x);
        //===============================================Update State
        if (mounted) {
          setNotifications(filteredNotificaitons);
          setFinData(newFinData);
        }
      } catch (e) {
        console.log("e", e);
      }
    };
    if (userIDLink) getUsersFinData();
    return () => {
      mounted = false;
    };
  }, [userID, userIDLink, paymentNotificaiton, refreshUserData]);

  useEffect(() => {
    /* Assessment logic, determines if it shows and which mode (first or not) 
      If first login, show first assessment When onboarding is complete
    */




    const checkAndShowAssessment = async () => {
      //Determine if assessment is complete for currMonth (asking questions about prev month)



      if (0) {
        //deprecated but keeping it for reference&backup
        const today = cookies.getDate();
        const loanStartDate = new Date(finData.loan.startDate);

        const monthsFromLoanStart = differenceInCalendarMonths(
          today,
          loanStartDate
        );
        if (monthsFromLoanStart === 0) return; //today sameMonth as loanStartDate > dont show any assessment, exit

        //ShowAssessment
        // If today is less or equal to 8th of current month.. then proceed or return;
        const date = startOfMonth(today);
        const dateToComp = addDays(date, 8);


        console.log(date)
        console.log(dateToComp)
        //const dateToComp = endOfMonth(today);

        // const dateToComp = addDays(date, 8);
        // const dateToComp = addDays(date, 6);
        if (isAfter(today, dateToComp)) {
          return;
        }
        //get currAssessment
        const prevMonthRange = {
          // startDate: prevMonthDate(today, { startOfMonth: true }),
          startDate: prevMonthDate(today, { startOfMonth: true }),
          endDate: prevMonthDate(today, { endOfMonth: true }),
        };
        const rawAssessments = await api.getUserAssessments(
          userIDLink,
          prevMonthRange
        ); // used to see if they have completed the 1st assessment
        const currAssessment = rawAssessments.data._embedded.selfAssessments;

        const currAssessmentIsComplete = !!currAssessment[0]; //Month's assessment is done (should only be 1), exit function
        if (currAssessmentIsComplete) return setCurrAssessmentComplete(true);
        setCurrAssessmentComplete(false);
        if (monthsFromLoanStart === 1) setIsFirstAssessment(true);
        if (monthsFromLoanStart > 1) setIsFirstAssessment(false);

        setShowAssessment(true);

      } else {

        if (cookies.getJWT()) {
          try {
            api.behaviourActivity()
              .then(resp => resp?.data)
              .then(res => {



                console.log(res);

                // if (res?.selfAssessment?.monthsFromLoanStart === 0) {
                //   return;
                // }
                // if (res?.selfAssessment?.monthsFromLoanStart === 1) setIsFirstAssessment(true);
                // if (res?.selfAssessment?.monthsFromLoanStart > 1) setIsFirstAssessment(false);
                if (res?.selfAssessment?.showAssessment) {
                  try {
                    localStorage.setItem('assessmentMonth', res?.selfAssessment?.assessmentMonth);
                    localStorage.setItem('paidParachuteOnTime', res?.selfAssessment?.paidParachuteOnTime);
                  } catch (error) {

                  }
                  setShowAssessment(true);
                  return;
                }

              }).catch(err => {
                // gridApi.hideOverlay();
                console.log(err);
                // params.successCallback([], 0);

              });
          } catch (error) {
            console.log(error)
          }


        }

      }



    };
    onboardingComplete &&
      finData?.goalAssessmentData &&
      checkAndShowAssessment();


    checkAndShowAssessment();
  }, [
    userIDLink,
    onboardingComplete,
    isFirstAssessment,
    finData?.goalAssessmentData,
    finData?.loan?.startDate,
  ]);

  const showOnboardingModal = onboardingComplete ? false : true;
  !overrideUserID &&
    window.history.pushState({ page: "client" }, "Client", "/client"); //Set browser url path


  return (
    <>

      {showMxModal && <MxConnectModal {...{ showMxModal, setShowMxModal }} />}

      <Container className="mb-4">
        <NotificationSection
          notifications={notifications}
          disabled={!!overrideUserID}
        />
      </Container>
      <Container>

        <br></br>

        <div className="clientApp grid">


          {(isMobileApp == "desktop") ? (

            <>
              {/* if deskyop */}
              <div className="col col1">
                <CashBackCard
                  data={finData?.cashBack}
                  overrideUserID={!!overrideUserID}
                  isCurrAssessmentComplete={isCurrAssessmentComplete}
                />
                <FicoCard data={finData?.fico} />

                <MonthlyGoalCard
                  overrideUserID={overrideUserID}
                  data={{ loan: finData?.loan, debt: finData?.debt }}
                />

                <WellnessScoreCard data={finData?.wellnessScore} />

              </div>
              <div className="col col2">
                <LoanDetails loanData={finData?.loan} />
                <MonthlySpendMxCard overrideUserID={overrideUserID} />

                {/* <MonthlyGoalCard
                  overrideUserID={overrideUserID}
                  data={{ loan: finData?.loan, debt: finData?.debt }}
                /> */}

                {/* <UtilizationCard data={finData?.utilization} /> */}
                <SurveyCard
                  id="moodCard"
                  mood
                  questionData={MOOD_QUESTION}
                  disabled={!!overrideUserID}
                  overrideUserLink={overrideUserLink}
                />
                <QuizCard
                  /* onBoarding modal currently lives within quizcard because of the quiz question within and its api calls, ideally this should be moved out but 
                the quizzes will still need to be linked. */
                  buildQuizNotification={buildQuizNotification}
                  setNotifications={setNotifications}
                  showOnboardingModal={showOnboardingModal}
                  UID={userID}
                  setOnboardingComplete={setOnboardingComplete}
                  disabled={!!overrideUserID}
                  overrideUserLink={overrideUserLink}
                />
              </div>
              <div className="col col3">
                <AccountsMxCard overrideUserID={overrideUserID} />
                {/* <WeekendSummary /> */}
                <PulseWidget overrideUserID={overrideUserID} />

                <SurveyCard
                  id="inspireCard"
                  inspire
                  questionData={todayInspireMe}
                  disabled={!!overrideUserID}
                  overrideUserLink={overrideUserLink}
                />

                <BudgetsMxCard overrideUserID={overrideUserID} />

              </div>
              {/* if deskyop */}

            </>

          ) : (

            <>
              {/* if mobile or tablet */}


              <div className="col">
                <LoanDetails loanData={finData?.loan} />
                <CashBackCard
                  data={finData?.cashBack}
                  overrideUserID={!!overrideUserID}
                  isCurrAssessmentComplete={isCurrAssessmentComplete}
                />
                <FicoCard data={finData?.fico} />

                <QuizCard
                  /* onBoarding modal currently lives within quizcard because of the quiz question within and its api calls, ideally this should be moved out but 
                the quizzes will still need to be linked. */
                  buildQuizNotification={buildQuizNotification}
                  setNotifications={setNotifications}
                  showOnboardingModal={showOnboardingModal}
                  UID={userID}
                  setOnboardingComplete={setOnboardingComplete}
                  disabled={!!overrideUserID}
                  overrideUserLink={overrideUserLink}
                />
                <MonthlyGoalCard
                  overrideUserID={overrideUserID}
                  data={{ loan: finData?.loan, debt: finData?.debt }}
                />
                <WellnessScoreCard data={finData?.wellnessScore} />

                <SurveyCard
                  id="moodCard"
                  mood
                  questionData={MOOD_QUESTION}
                  disabled={!!overrideUserID}
                  overrideUserLink={overrideUserLink}
                />

                <SurveyCard
                  id="inspireCard"
                  inspire
                  questionData={todayInspireMe}
                  disabled={!!overrideUserID}
                  overrideUserLink={overrideUserLink}
                />
                <AccountsMxCard overrideUserID={overrideUserID} />
                <PulseWidget overrideUserID={overrideUserID} />

                <MonthlySpendMxCard overrideUserID={overrideUserID} />

                <BudgetsMxCard overrideUserID={overrideUserID} />



              </div>


              {/* if mobile or tablet */}

            </>



          )}





        </div>

        {/* {showOnboardingModal && <OnboardingModal UID={userID} quiz={onBoardQuiz} />} */}
        {!overrideUserID && showAssessment && (
          <GoalAssessment
            userIDLink={userIDLink}
            goalAssessmentData={finData?.goalAssessmentData}
            firstTime={isFirstAssessment}
            // updateCBHistoryState={updateCBHistoryState}
            setRefreshUserData={setRefreshUserData}
          />
        )}
        {/* <row id="moneyMap" style={{ width: "100vw" }}>
          
        </row> */}
      </Container >


      <Container className="pt-4">
        <Row>
          <Col> {mxDataList ? (
            <>

              <Card>


                <Card.Body> MX CONNECTION

                  {/* {Object.keys(mxDataList).length > 0 ? (<Button variant="outline-secondary" style={{ margin: '5px' }} className="float-end" onClick={() => window.location.href = "#moneyMap"} >
                    Transactions
                  </Button>) : ('')}
                  {Object.keys(mxDataList).length > 0 ? (<Button variant="outline-secondary" style={{ margin: '5px' }} className="float-end" onClick={() => window.location.href = "#accountsWidget"} >
                    Accounts
                  </Button>) : ('')} */}


                  <Button style={{ margin: '5px' }} variant="dark" className="float-end" onClick={() => setShowMxModal(true)}>
                    {Object.keys(mxDataList).length > 0 ? ('Reconnect') : ('Connect')}
                  </Button>


                </Card.Body>

              </Card>


              {Object.keys(mxDataList).length !== 0 ? (

                <>
                  {/* <br></br> */}
                  <Card >
                    <Card.Body >
                      <h3>Total Connections: {Object.keys(mxDataList).length}</h3>
                      <ListGroup height={500}>
                        {Object.entries(mxDataList).map(([key, value]) => (
                          <ListGroup.Item>{key} : {value}</ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </>
              ) : ('')}


            </>) : (<><Skeleton height={90} />
              <Skeleton height={30} count={3} />
            </>)}
          </Col>
        </Row>
        <br></br> <br></br>
      </Container>

    </>

  );
}

export default ClientView;
