import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';


import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'

import Container from 'react-bootstrap/Container';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import cookies from '../../../utils/cookies';
export default function ReportsViewer() {


  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  
  const [isLoading2, setIsLoading2] = useState(false);
  
  const typesArr = ['BB', 'RPA'];
  const [selectedType, setSelectedType] = useState('BB');

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };






  useEffect(() => {

  }, []);



  const handleDownloadCashFlow = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      let data = {
        "subType": selectedType
      };
      api.downloadCashFlowReportByType(data).then(res => {
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
      });

    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  const handleDownloadWLU = async () => {
    if (isLoading1) {
      return;
    }
    try {
      setIsLoading1(true);
      let data = {
      };
      api.downloadWLUReport(data).then(res => {
        setIsLoading1(false);
      }).catch(err => {
        setIsLoading1(false);
      });

    } catch (e) {
      setIsLoading1(false);
      console.error(e);
    }
  };

  const handleDownloadTGI = async () => {
    if (isLoading2) {
      return;
    }
    try {
      setIsLoading2(true);
      let data = {
      };
      api.downloadTGIReport(data).then(res => {
        setIsLoading2(false);
      }).catch(err => {
        setIsLoading2(false);
      });

    } catch (e) {
      setIsLoading2(false);
      console.error(e);
    }
  };
  


  return (
    <>
      <div>
        <div>
          <br></br>
          <h1>{`Reports`}</h1>
          <hr>
          </hr>
          <h2>Cash Flow</h2>
          <h2>Select type to download</h2>
          <div>
            <Form.Select
              id="quizMonth"
              className="mr-8"
              aria-label="Type to download"
              onChange={handleTypeChange}
              value={selectedType}
            >
              {Object.values(typesArr)?.map((i) => (
                <option key={`${i}`} value={i}>
                  {i}
                </option>
              ))}
            </Form.Select>
            <br></br>
            <Button variant="dark" onClick={handleDownloadCashFlow}>Download  {isLoading && (
              <>
                {/* <p>Submitting plan details, please wait...</p> */}
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                />
              </>
            )}</Button>
          </div>



          <hr></hr>
          <h2>WLU</h2>
          {/* <h2>Select type to download</h2> */}
          <div>
            {/* <Form.Select
              id="quizMonth"
              className="mr-8"
              aria-label="Type to download"
              onChange={handleTypeChange}
              value={selectedType}
            >
              {Object.values(typesArr)?.map((i) => (
                <option key={`${i}`} value={i}>
                  {i}
                </option>
              ))}
            </Form.Select>
            <br></br> */}
            <Button variant="dark" onClick={handleDownloadWLU}>Download  {isLoading1 && (
              <>
                {/* <p>Submitting plan details, please wait...</p> */}
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                />
              </>
            )}</Button>
          </div>
          <hr></hr>
          <h2>TGI</h2>
          <div>
            
            <Button variant="dark" onClick={handleDownloadTGI}>Download  {isLoading2 && (
              <>
                {/* <p>Submitting plan details, please wait...</p> */}
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                />
              </>
            )}</Button>
          </div>
          <hr />
        </div>
      </div>
    </>


  );
}
