import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
// import icon from '../../assets/Logo_Icon.svg';
// import { LINKS } from '../../utils/constants';
import TextButton from '../TextButton';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
// import { PRE_QUAL } from '../../utils/constants';
import Tooltip from 'react-bootstrap/Tooltip';
// import Button from 'react-bootstrap/Button';

import infoIcon from '../../assets/icons/infoIcon.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import { useLocation } from 'react-router-dom';
export default function TellYourStory({ navForm, updatePreQualState, submitToAPI, stateData, status }) {

  // const location = useLocation();
  // const searchParams1 = new URLSearchParams(location.search);
  // const id = searchParams1.get("applicantKey");
  // const [customerReferenceId] = useState(id);

  const [formData, setFormData] = useState(stateData || {});

  useEffect(() => {
    stateData && setFormData({ ...stateData });
  }, [stateData]);

  console.log('formData', formData);


  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    return updatePreQualState('freeFormQuestion', formData);

  };

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    const questionIndex = key.slice(1);
    console.log('chahe', key, questionIndex, value);
    if (value.length > 1000) {
      return;
    }

    setFormData((p) => {
      p.freeFormQuestion = value;
      return { ...p };
    });
  };


  console.log('formData', formData);
  return (
    <>
      <CardContainer>
        <div>


          <h2>At Parachute, we understand life happens and it can be expensive and stressful.</h2>

          <p>
            Believe it or not, knowing your situation helps us when approving your loan.</p>

          <Form.Group className="" controlId="freeFormQuestion">
            <Form.Label>
              
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  Job loss/loss of Income, divorce, medical bills, supporting family - It all matters to us. The more information you can provide for your existing debts, delinquent accounts, missed or late payments or any other information that resulted in you needing the loans you now want to consolidate, the better. It all helps us help you.
                </Tooltip>
              )}
              placement="bottom"
            ><img src={infoIcon} alt="Info Text" />
            </OverlayTrigger>
            &nbsp;
              Tell us your story.    
              
             
            
            </Form.Label>
            <Form.Control  style={{ backgroundColor: `#eee` }} as="textarea" value={formData.freeFormQuestion} rows={12} placeholder="Job loss/loss of Income, divorce, medical bills, supporting family - It all matters to us. The more information you can provide for your existing debts, delinquent accounts, missed or late payments or any other information that resulted in you needing the loans you now want to consolidate, the better. It all helps us help you." onChange={handleChange} required />
            {(formData?.freeFormQuestion?.length ===0 || !formData?.freeFormQuestion?.length) && <p className="error">*Required</p>}
            <Form.Label>Total characters - {formData?.freeFormQuestion?.length ? formData?.freeFormQuestion?.length : 0}/1000</Form.Label>
          
          </Form.Group>




          <WideBtn onClick={handleSubmit} disabled={!formData?.freeFormQuestion?.length || status.loading} id="submitTellYourStory">
            {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Submit`}
          </WideBtn>
          {status.error && <p className="error">{status.error}</p>}
          <TextButton
            onClick={(e) => {
              e.preventDefault();
              navForm('back');
            }}
          >
            Back
          </TextButton>
        </div>
      </CardContainer>
    </>
  );
}
