/*
  
  []  TODO - Need to clear income details when the type is changed, or at least the ones no seen 
  
  
  */

import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import Form from 'react-bootstrap/Form';
import { PRE_QUAL } from '../../utils/constants';
import TextButton from '../TextButton';
import { validateAndFormat } from './validatePreQual';
import Spinner from 'react-bootstrap/Spinner';
// import InfoBubble from './InfoBubble';
export default function IncomeDetails({ navForm, updatePreQualState, stateData, status }) {
  // RESIDENCY_STATUS
  const { INCOME_TYPES, CREDIT_SCORES, YES_NO, MONTHS, DAYS, YEARS, YEARS_LESS18 } =
    PRE_QUAL; //CONSTANTS for Form Options


  let customerReferenceIdData = null;

  const [error, setError] = useState(null);
  try {
    customerReferenceIdData = JSON.parse(localStorage.getItem('customerReferenceIdData'));


  } catch (error) {

  }

  const clearError = () => {
    setError(null);
  };
  const [incomeDetails, setIncomeDetails] = useState({
    /* Only Employed has extra fields (name, title, work # ) */
    primaryIncomeType: stateData?.primaryIncomeType || customerReferenceIdData?.applicantIncome?.incomeType || '',
    primaryGrossIncome: stateData?.primaryGrossIncome || customerReferenceIdData?.applicantIncome?.grossIncome ? customerReferenceIdData?.applicantIncome?.grossIncome.toString() : "" || '',
    primaryEmployerName: stateData?.primaryEmployerName || customerReferenceIdData?.applicantIncome?.employerName || '',
    primaryJobTitle: stateData?.primaryJobTitle || customerReferenceIdData?.applicantIncome?.jobTitle || '',
    primaryWorkPhone: stateData?.primaryWorkPhone || customerReferenceIdData?.applicantIncome?.workPhone || '',
    /* Secondary Is Optional */
    secondaryIncomeType: stateData?.secondaryIncomeType || customerReferenceIdData?.applicantIncome2?.incomeType || '',
    secondaryGrossIncome: stateData?.secondaryGrossIncome || customerReferenceIdData?.applicantIncome2?.grossIncome ? customerReferenceIdData?.applicantIncome2?.grossIncome.toString() : "" || '',
    secondaryEmployerName: stateData?.secondaryEmployerName || customerReferenceIdData?.applicantIncome2?.employerName || '',
    secondaryJobTitle: stateData?.secondaryJobTitle || customerReferenceIdData?.applicantIncome2?.jobTitle || '',
    secondaryWorkPhone: stateData?.secondaryWorkPhone || customerReferenceIdData?.applicantIncome2?.workPhone || '',
    /* Other Info */
    residencyStatus: stateData?.residencyStatus || customerReferenceIdData?.applicantResidency?.residentialStatus || '',
    creditScore: stateData?.creditScore || customerReferenceIdData?.creditScore || '',
    scoreSource: stateData?.scoreSource || customerReferenceIdData?.scoreSource || '',
    dobMonth: stateData?.dobMonth || customerReferenceIdData?.dobMonth || '',
    dobDay: stateData?.dobDay || customerReferenceIdData?.dobDay || '',
    dobYear: stateData?.dobYear || customerReferenceIdData?.dobYear || '',
    hasBankrupcy: stateData?.hasBankrupcy || customerReferenceIdData?.hasBankruptcy || null,
    hasProvID: stateData?.hasProvID || customerReferenceIdData?.hasProvID || null,
    hasPassport: stateData?.hasPassport || customerReferenceIdData?.hasPassport || null,
    outstandingPaydayLoans: stateData?.outstandingPaydayLoans || customerReferenceIdData?.outstandingPaydayLoans || null,
    unpaidIncomeTaxes: stateData?.unpaidIncomeTaxes || customerReferenceIdData?.unpaidIncomeTaxes || null,
  
   
  });

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleIsMobile = () => setIsMobileView(window.document.body.clientWidth < 1250);
    window.addEventListener('resize', handleIsMobile);
    handleIsMobile();
    return () => {
      window.removeEventListener('resize', handleIsMobile);
    };
  }, [setIsMobileView]);

  useEffect(() => {
    stateData && setIncomeDetails({ ...stateData });
  }, [stateData]);

  const [showSecondaryIncomeForm, setShowSecondaryIncomeForm] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    if (value === 'Yes') value = true;
    if (value === 'No') value = false;

    const formattedValue = validateAndFormat('income', key, value);
    if (formattedValue === undefined || formattedValue === null) return;

    const prevState = { ...incomeDetails };

    if (key === 'primaryIncomeType' || key === 'secondaryIncomeType') {
      /* If changing type of employment FROM employed (primary or secondary), clear employer related */
      //Has the state changed
      const prevValue = prevState[key];
      if (prevValue !== value) {
        //clear associated fields
        if (key.includes('primary')) {
          prevState.primaryEmployerName = '';
          prevState.primaryJobTitle = '';
          prevState.primaryWorkPhone = '';
          prevState.primaryHiredDay = '';
          prevState.primaryHiredMonth = '';
          prevState.primaryHiredYear = '';
        }
        if (key.includes('secondary')) {
          prevState.secondaryEmployerName = '';
          prevState.secondaryJobTitle = '';
          prevState.secondaryWorkPhone = '';
          prevState.secondaryHiredDay = '';
          prevState.secondaryHiredMonth = '';
          prevState.secondaryHiredYear = '';
        }
      }
    }

    const newData = {
      ...prevState,
      [key]: formattedValue,
    };
    setIncomeDetails(newData);
    // setIncomeDetails((p) => {
    //   const newData = { ...p };
    //   newData[key] = formattedValue;
    //   return newData;
    // });
  };
  const isPrimaryEmployed = incomeDetails.primaryIncomeType === 'Employed';
  const isSecondaryEmployed = incomeDetails.secondaryIncomeType === 'Employed';

  const handleAddSecondaryIncome = (e) => {
    e.preventDefault();
    setShowSecondaryIncomeForm(true);
  };

  const handleDoB = (e, field) => {
    e.preventDefault();
    const value = e.target.value;
    let key = '';
    switch (field) {
      case 'month':
        key = 'dobMonth';
        break;
      case 'day':
        key = 'dobDay';
        break;
      case 'year':
        key = 'dobYear';
        break;
      default:
    }

    const formattedValue = validateAndFormat('income', key, value);
    if (formattedValue === undefined || formattedValue === null) return;

    key &&
      setIncomeDetails((p) => {
        return { ...p, [key]: formattedValue };
      });
  };

  const handleHiredDate = (e, field) => {
    e.preventDefault();
    const key = e.target.id;
    const value = e.target.value;
    // let key = '';
    // switch (field) {
    //   case 'primaryMonth':
    //     key = 'primaryHiredMonth';
    //     break;
    //   case 'primaryDay':
    //     key = 'primaryHiredDay';
    //     break;
    //   case 'primaryYear':
    //     key = 'primaryHiredYear';
    //     break;
    //   case 'secondaryMonth':
    //     key = 'secondaryHiredMonth';
    //     break;
    //   case 'secondaryDay':
    //     key = 'secondaryHiredDay';
    //     break;
    //   case 'secondaryYear':
    //     key = 'secondaryHiredYear';
    //     break;
    //   default:
    // }

    const formattedValue = validateAndFormat('income', key, value);
    if (formattedValue === undefined || formattedValue === null) return;
    key &&
      setIncomeDetails((p) => {
        return { ...p, [key]: formattedValue };
      });
  };

  const handleSubmit = (e) => {

    clearError();
    e.preventDefault && e.preventDefault();
    /* Need to stop the update if the form has any invalid
      - Ideally look at event and check the form if any invalid fields
      - worst case need to run full manual validation then update state to control the update... 
      with field `isInvalid` class of 'is-invalid' can be added
      then e.tar.outer or inner html can check for that class (likely hacky but is a quick solution)
      */

    setValidated(true);
    const formElement = e.target.innerHTML;
    const formContainsInvalidField = formElement.includes('is-invalid');
    const PREQUAL_STATE_KEY = 'income';

    if (!document.getElementById('dobMonth').value) {
      setError('DOB Month is required');
      window.scrollTo(0, 0);
      return;
    }
    if (!document.getElementById('dobDay').value) {
      setError('DOB Day is required');
      window.scrollTo(0, 0);
      return;
    }
    if (!document.getElementById('dobYear').value) {
      setError('DOB Year is required');
      window.scrollTo(0, 0);
      return;
    }

    clearError();
    if (validated && formContainsInvalidField !== true) {
      return updatePreQualState(PREQUAL_STATE_KEY, incomeDetails); //Need validated first or classes wont be set
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (validated === true) {
      handleSubmit({ target: window.document.getElementsByClassName('applyForm')[0], preventDefault: () => { } });
    }
    //eslint-disable-next-line
  }, [validated]);

  const handleYesNoValue = (stateValue) => {
    let val = '';
    if (stateValue === true) val = 'Yes';
    if (stateValue === false) val = 'No';
    return val;
  };

  console.log('isMobileView', isMobileView);

  console.log('TEST isInvalid', validated && (incomeDetails.dobYear.length < 4 || !incomeDetails.dobYear));
  console.log('incomeDetails', incomeDetails);
  return (
    <>
      <CardContainer>
        <div>
          {/* <InfoBubble>
            <p>
            Parachute is the only loan where you can leave with more savings than
            when you started.
            </p>
          </InfoBubble> */}
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">{
              !customerReferenceIdData ? (`This is the beginning of a beautiful friendship. We want to know more!`) : (`ClearScore sent us some of your information. In order to Pre-Approve you we will need to know a little more!`)

            }</h3>
            {/*  <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p> */}
          </div>
          <hr className="mv-24" />
          <Form className="applyForm" onSubmit={handleSubmit} noValidate>
            <section className="employment">
              <Form.Group className="mb-3" controlId="primaryIncomeType">
                <Form.Label>My main source of income is:</Form.Label>
                <Form.Select
                  aria-label="Primary Income Type"
                  onChange={handleChange}
                  value={incomeDetails.primaryIncomeType}
                  required
                  isInvalid={validated && !incomeDetails.primaryIncomeType}
                  readOnly={status.loading}
                >
                  {INCOME_TYPES.map((i) => (
                    <option key={`incomeType-${i}`} value={i}>
                      {i}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {isPrimaryEmployed && (
                <Form.Group className="mb-3">
                  <Form.Label>I was hired on:</Form.Label>
                  <span style={{ display: 'flex' }}>
                    <Form.Select
                      id="primaryHiredMonth"
                      className="mr-8"
                      aria-label="Month"
                      onChange={handleHiredDate}
                      value={incomeDetails.primaryHiredMonth}
                      isInvalid={validated && !incomeDetails.primaryHiredMonth}
                      required
                      readOnly={status.loading}
                    >
                      {MONTHS.map((i) => (
                        <option key={`priHirMon-${i}`} value={i}>
                          {i}
                        </option>
                      ))}
                    </Form.Select>
                    {isMobileView ? (
                      <Form.Select
                        id="primaryHiredDay"
                        className="mr-8"
                        aria-label="Day"
                        onChange={handleHiredDate}
                        value={incomeDetails.primaryHiredDay}
                        isInvalid={validated && !incomeDetails.primaryHiredDay}
                        required
                        readOnly={status.loading}
                      >
                        {DAYS?.map((i) => (
                          <option key={`priHireDay-${i}`} value={i}>
                            {i}
                          </option>
                        ))}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        id="primaryHiredDay"
                        className="mr-8"
                        type="text"
                        aria-label="Day"
                        placeholder="DD"
                        min={1}
                        max={31}
                        onChange={handleHiredDate}
                        value={incomeDetails.primaryHiredDay}
                        isInvalid={validated && !incomeDetails.primaryHiredDay}
                        required
                        readOnly={status.loading}
                      />
                    )}
                    {isMobileView ? (
                      <Form.Select
                        id="primaryHiredYear"
                        className="mr-8"
                        aria-label="Year"
                        onChange={handleHiredDate}
                        value={incomeDetails.primaryHiredYear}
                        isInvalid={validated && !incomeDetails.primaryHiredYear}
                        required
                        readOnly={status.loading}
                      >
                        {YEARS?.map((i) => (
                          <option key={`dobY-${i}`} value={i}>
                            {i}
                          </option>
                        ))}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        id="primaryHiredYear"
                        type="text"
                        aria-label="Year"
                        placeholder="YYYY"
                        onChange={handleHiredDate}
                        value={incomeDetails.primaryHiredYear}
                        isInvalid={
                          validated && (incomeDetails.primaryHiredYear.length < 4 || !incomeDetails.primaryHiredYear)
                        }
                        required
                        readOnly={status.loading}
                      />
                    )}
                  </span>
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="primaryGrossIncome">
                <Form.Label>My yearly gross income (main source of income) is:</Form.Label>
                <div className="inputDollar-container">
                  <span className="inputDollar-symbol">$</span>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    placeholder="Primary Income amount (Gross)"
                    value={incomeDetails.primaryGrossIncome}
                    isInvalid={validated && !incomeDetails.primaryGrossIncome}
                    required
                    readOnly={status.loading}
                  />
                </div>
              </Form.Group>

              {isPrimaryEmployed && (
                <section>
                  {/* Employer Detials if incomeType === "Employed" */}
                  <Form.Group className="mb-3" controlId="primaryEmployerName">
                    <Form.Label>I work for:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your Employer's name"
                      onChange={handleChange}
                      value={incomeDetails.primaryEmployerName}
                      required
                      isInvalid={validated && !incomeDetails.primaryEmployerName}
                      readOnly={status.loading}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="primaryJobTitle">
                    <Form.Label>My title is:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your job title"
                      onChange={handleChange}
                      value={incomeDetails.primaryJobTitle}
                      required
                      isInvalid={validated && !incomeDetails.primaryJobTitle}
                      readOnly={status.loading}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="primaryWorkPhone">
                    <Form.Label>My work phone (enter your cell/land line if not applicable) is:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Work phone number"
                      onChange={handleChange}
                      value={incomeDetails.primaryWorkPhone}
                      required
                      isInvalid={
                        validated && (incomeDetails.primaryWorkPhone.length < 10 || !incomeDetails.primaryWorkPhone)
                      }
                      readOnly={status.loading}
                    />
                  </Form.Group>
                </section>
              )}
              {/* Show add button if  */}
              {incomeDetails.primaryIncomeType && showSecondaryIncomeForm !== true && (
                <WideBtn
                  variant="outline-secondary"
                  onClick={handleAddSecondaryIncome}
                  disabled={status.loading}
                  style={{ marginTop: '8px', marginBottom: '0' }}
                >
                  Add secondary income
                </WideBtn>
              )}
              {showSecondaryIncomeForm === true && (
                <>
                  <Form.Group className="mb-3" controlId="secondaryIncomeType">
                    <Form.Label>Secondary Income Type</Form.Label>
                    <Form.Select
                      aria-label="Secondary Income Type"
                      onChange={handleChange}
                      value={incomeDetails.secondaryIncomeType}
                      readOnly={status.loading}
                    >
                      {INCOME_TYPES.map((i) => (
                        <option key={`incomeType2-${i}`} value={i}>
                          {i}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {isSecondaryEmployed && (
                    <Form.Group className="mb-3">
                      <Form.Label>Hired Date</Form.Label>
                      <span style={{ display: 'flex' }}>
                        <Form.Select
                          id="secondaryHiredMonth"
                          className="mr-8"
                          aria-label="Month"
                          onChange={handleHiredDate}
                          value={incomeDetails.secondaryHiredMonth}
                          isInvalid={validated && !incomeDetails.secondaryHiredMonth}
                          required
                          readOnly={status.loading}
                        >
                          {MONTHS.map((i) => (
                            <option key={`priHirMon-${i}`} value={i}>
                              {i}
                            </option>
                          ))}
                        </Form.Select>
                        {isMobileView ? (
                          <Form.Select
                            id="secondaryHiredDay"
                            className="mr-8"
                            aria-label="Day"
                            onChange={handleHiredDate}
                            value={incomeDetails.secondaryHiredDay}
                            isInvalid={validated && !incomeDetails.secondaryHiredDay}
                            required
                            readOnly={status.loading}
                          >
                            {DAYS?.map((i) => (
                              <option key={`priHireDay-${i}`} value={i}>
                                {i}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            id="secondaryHiredDay"
                            className="mr-8"
                            type="text"
                            aria-label="Day"
                            placeholder="DD"
                            min={1}
                            max={31}
                            onChange={handleHiredDate}
                            value={incomeDetails.secondaryHiredDay}
                            isInvalid={validated && !incomeDetails.secondaryHiredDay}
                            required
                            readOnly={status.loading}
                          />
                        )}
                        {isMobileView ? (
                          <Form.Select
                            id="secondaryHiredYear"
                            className="mr-8"
                            aria-label="Year"
                            onChange={handleHiredDate}
                            value={incomeDetails.secondaryHiredYear}
                            isInvalid={validated && !incomeDetails.secondaryHiredYear}
                            required
                            readOnly={status.loading}
                          >
                            {YEARS?.map((i) => (
                              <option key={`secHirYr-${i}`} value={i}>
                                {i}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <Form.Control
                            id="secondaryHiredYear"
                            type="text"
                            aria-label="Year"
                            placeholder="YYYY"
                            onChange={handleHiredDate}
                            value={incomeDetails.secondaryHiredYear}
                            isInvalid={
                              validated &&
                              (incomeDetails.secondaryHiredYear.length < 4 || !incomeDetails.secondaryHiredYear)
                            }
                            required
                            readOnly={status.loading}
                          />
                        )}
                      </span>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3" controlId="secondaryGrossIncome">
                    <Form.Label>Yearly Gross Income (Secondary)</Form.Label>
                    <div className="inputDollar-container">
                      <span className="inputDollar-symbol">$</span>
                      <Form.Control
                        type="text"
                        placeholder="Secondary Gross Income"
                        onChange={handleChange}
                        value={incomeDetails.secondaryGrossIncome}
                        required={!!incomeDetails.secondaryIncomeType} //Only require if a 2nd income is selected
                        isInvalid={
                          !!incomeDetails.secondaryIncomeType && validated && !incomeDetails.secondaryGrossIncome
                        }
                        readOnly={status.loading}
                      />
                    </div>
                  </Form.Group>

                  {isSecondaryEmployed && (
                    <section>
                      {/* Employer Detials if incomeType === "Employed" */}
                      <Form.Group className="mb-3" controlId="secondaryEmployerName">
                        <Form.Label>Employer</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Secondary employer name"
                          onChange={handleChange}
                          value={incomeDetails.secondaryEmployerName}
                          isInvalid={validated && !incomeDetails.secondaryEmployerName}
                          readOnly={status.loading}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="secondaryJobTitle">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Secondary job title"
                          onChange={handleChange}
                          value={incomeDetails.secondaryJobTitle}
                          isInvalid={validated && !incomeDetails.secondaryJobTitle}
                          readOnly={status.loading}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="secondaryWorkPhone">
                        <Form.Label>Work Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Secondary work phone number"
                          onChange={handleChange}
                          value={incomeDetails.secondaryWorkPhone}
                          isInvalid={
                            validated &&
                            (incomeDetails.secondaryWorkPhone.length < 10 || !incomeDetails.secondaryWorkPhone)
                          }
                          readOnly={status.loading}
                        />
                      </Form.Group>
                    </section>
                  )}
                </>
              )}
            </section>

            {incomeDetails.primaryIncomeType && <hr className="mv-24" />}

            {/* <Form.Group className="mb-3" controlId="residencyStatus">
              <Form.Label>My residency status is:</Form.Label>
              <Form.Select
                aria-label="Canadian Residency Status"
                onChange={handleChange}
                value={incomeDetails.residencyStatus}
                isInvalid={validated && !incomeDetails.residencyStatus}
                required
                readOnly={status.loading}
              >
                {RESIDENCY_STATUS.map((i) => (
                  <option key={`resStatus-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}



            {!customerReferenceIdData ? (<Form.Group className="mb-3" controlId="creditScore">
              <Form.Label>My credit score is:</Form.Label>
              <Form.Select
                aria-label="Credit Score"
                onChange={handleChange}
                value={incomeDetails.creditScore}
                isInvalid={validated && !incomeDetails.creditScore}
                required
                readOnly={status.loading}
              >
                {CREDIT_SCORES.map((i) => (
                  <option key={`credScore-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>) : ('')}




            {/* <Form.Group className="mb-3" controlId="scoreSource">
              <Form.Label>I know that because I check:</Form.Label>
              <Form.Select
                aria-label="Where did you get your credit score?"
                onChange={handleChange}
                value={incomeDetails.scoreSource}
                // isInvalid={validated && !incomeDetails.scoreSource}
                readOnly={status.loading}
              >
                {CREDIT_SOURCES.map((i) => (
                  <option key={`credSrc-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>I was born:</Form.Label>
              <span style={{ display: 'flex' }}>
                <Form.Select
                  id="dobMonth"
                  className="mr-8"
                  aria-label="Month of Birth"
                  onChange={(e) => handleDoB(e, 'month')}
                  value={incomeDetails.dobMonth}
                  isInvalid={validated && !incomeDetails.dobMonth}
                  required
                  readOnly={status.loading}
                >
                  {MONTHS.map((i) => (
                    <option key={`dobM-${i}`} value={i}>
                      {i}
                    </option>
                  ))}
                </Form.Select>
                {isMobileView ? (
                  <Form.Select
                    id="dobDay"
                    className="mr-8"
                    aria-label="Day of Birth"
                    onChange={(e) => handleDoB(e, 'day')}
                    value={incomeDetails.dobDay}
                    isInvalid={validated && !incomeDetails.dobDay}
                    required
                    readOnly={status.loading}
                  >
                    {DAYS?.map((i) => (
                      <option key={`dobD-${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Form.Control
                    id="dobDay"
                    className="mr-8"
                    type="text"
                    aria-label="Day of Birth"
                    placeholder="DD"
                    min={1}
                    max={31}
                    onChange={(e) => handleDoB(e, 'day')}
                    value={incomeDetails.dobDay}
                    isInvalid={validated && !incomeDetails.dobDay}
                    required
                    readOnly={status.loading}
                  />
                )}
                {isMobileView ? (
                  <Form.Select
                    id="dobYear"
                    className="mr-8"
                    aria-label="Day of Birth"
                    onChange={(e) => handleDoB(e, 'year')}
                    value={incomeDetails.dobYear}
                    isInvalid={validated && !incomeDetails.dobYear}
                    required
                    readOnly={status.loading}
                  >
                    {YEARS_LESS18?.map((i) => (
                      <option key={`dobY-${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  <Form.Control
                    id="dobYear"
                    type="text"
                    aria-label="Year of Birth"
                    placeholder="YYYY"
                    // max={2022 - 18}
                    // min={1901}
                    onChange={(e) => handleDoB(e, 'year')}
                    value={incomeDetails.dobYear}
                    isInvalid={validated && (incomeDetails.dobYear.length < 4 || !incomeDetails.dobYear)}
                    required
                    readOnly={status.loading}
                  />
                )}
                {/* Should year have min of this year - 18 or 19, need to account for just turned 18... */}
              </span>
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="hasBankrupcy">
              <Form.Label>Have you ever been bankrupt or filed a Consumer Proposal?</Form.Label>
              <Form.Select
                aria-label="Do you have a bankruptcy or consumer proposal on your credit bureau?"
                onChange={handleChange}
                value={handleYesNoValue(incomeDetails.hasBankrupcy)}
                isInvalid={
                  validated && (incomeDetails.hasBankrupcy === null || incomeDetails.hasBankrupcy === undefined)
                }
                required
                readOnly={status.loading}
              >
                {YES_NO.map((i) => (
                  <option key={`hasBankrupcy-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}

            <Form.Group className="mb-3" controlId="hasProvID">
              <Form.Label>I have a driver’s license or provincial ID:</Form.Label>
              <Form.Select
                aria-label="Do you have a Driver’s License or Provincial ID?"
                onChange={handleChange}
                value={handleYesNoValue(incomeDetails.hasProvID)}
                isInvalid={validated && (incomeDetails.hasProvID === null || incomeDetails.hasProvID === undefined)}
                required
                readOnly={status.loading}
              >
                {YES_NO.map((i) => (
                  <option key={`hasID-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="hasPassport">
              <Form.Label>I have a Passport or Birth Certificate:</Form.Label>
              <Form.Select
                aria-label="Do you have a Passport or Birth Certificate?"
                onChange={handleChange}
                value={handleYesNoValue(incomeDetails.hasPassport)}
                isInvalid={validated && (incomeDetails.hasPassport === null || incomeDetails.hasPassport === undefined)}
                required
                readOnly={status.loading}
              >
                {YES_NO.map((i) => (
                  <option key={`hasPass-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="outstandingPaydayLoans">
              <Form.Label> As of today do you have any outstanding 
 payday loans?</Form.Label>
              <Form.Select
                aria-label="As of today do you have any outstanding 
                payday loans?"
                onChange={handleChange}
                value={handleYesNoValue(incomeDetails.outstandingPaydayLoans)}
                isInvalid={validated && (incomeDetails.outstandingPaydayLoans === null || incomeDetails.outstandingPaydayLoans === undefined)}
                required
                readOnly={status.loading}
              >
                {YES_NO.map((i) => (
                  <option key={`outstandingPaydayLoans-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>


             <Form.Group className="mb-3" controlId="unpaidIncomeTaxes">
              <Form.Label>Do you owe any unpaid income taxes?</Form.Label>
              <Form.Select
                aria-label="Do you owe any unpaid income taxes?"
                onChange={handleChange}
                value={handleYesNoValue(incomeDetails.unpaidIncomeTaxes)}
                isInvalid={validated && (incomeDetails.unpaidIncomeTaxes === null || incomeDetails.unpaidIncomeTaxes === undefined)}
                required
                readOnly={status.loading}
              >
                {YES_NO.map((i) => (
                  <option key={`unpaidIncomeTaxes-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> 


               <WideBtn disabled={status.loading} style={{ marginTop: '8px' }} id="submitIncomeDetails">
              {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Next`}
            </WideBtn>
            {error && <p className="error">{error}</p>}
       
            {status.error && <p className="error">{status.error}</p>}
            
            <TextButton
              disabled={status.loading}
              onClick={() => {
                navForm('back');
              }}
            >
              Back
            </TextButton>
          </Form>

      
        </div>
      </CardContainer>
    </>
  );
}
