// import Cookies from 'js-cookie';
// import ReactGA from 'react-ga';
import moment from 'moment';
import 'moment-timezone';

//main, qa, develop
let env = "main";

//[true,false] - true for mobile app, false for browser
// export let isMobileApp = false;

export let environment = env;
export let processenv = {};
export let app_version = "2.0.3";
export let adminID = "14";
if (env === 'develop') {
  processenv = {
    "REACT_APP_BASE_URL": "https://develop--ionic-myparachute.netlify.app",
    "REACT_APP_ENV": "api.dev",
    "REACT_APP_API": "myparachute.co:8080/api/v1",
    "REACT_APP_HEAP_DEV_ID": "1685518998",
    "REACT_APP_FLINKS_LINK": "https://parachutecashdev-iframe.private.fin.ag/v2/?scheduleRefresh=true&enhancedMFA=true&demo=true",
    "REACT_APP_LENDERS_USERNAME": "whitehat",
    "REACT_APP_TWILIO_LINK": "https://verify-5040-i1bw5u.twil.io"
  };
  adminID = "948";
} else if (env === 'main') {
  processenv = {
    "REACT_APP_BASE_URL": "https://app.myparachute.co",
    "REACT_APP_ENV": "api.prod",
    "REACT_APP_API": "myparachute.co:8080/api/v1",
    "REACT_APP_HEAP_DEV_ID": "1761564168",
    "REACT_APP_FLINKS_LINK": "https://parachutecash-iframe.private.fin.ag/v2/?scheduleRefresh=true&enhancedMFA=true",
    "REACT_APP_LENDERS_USERNAME": "whitehat",
    "REACT_APP_TWILIO_LINK": "https://verify-5040-i1bw5u.twil.io"
  };
  adminID = "14";
} else if (env === 'qa') {
  processenv = {
    "REACT_APP_BASE_URL": "https://qa--ionic-myparachute.netlify.app",
    "REACT_APP_ENV": "api.prod",
    "REACT_APP_API": "myparachute.co:8080/api/v1",
    "REACT_APP_HEAP_DEV_ID": "1685518998",
    "REACT_APP_FLINKS_LINK": "https://parachutecashdev-iframe.private.fin.ag/v2/?scheduleRefresh=true&enhancedMFA=true&demo=true",
    "REACT_APP_LENDERS_USERNAME": "whitehat",
    "REACT_APP_TWILIO_LINK": "https://verify-5040-i1bw5u.twil.io"
  };
  adminID = "14";
}

const sessionDaysToExpire = 3;

export const setJWT = (value) => {
  localStorage.setItem('jwt', value, { expires: sessionDaysToExpire });
};

export const setUserID = (value) => {
  localStorage.setItem('userID', value, { expires: sessionDaysToExpire });
};

export const setUserDisplayName = (value) => {
  localStorage.setItem('userDisplayName', value, { expires: sessionDaysToExpire });
};

export const getUserID = () => {
  return localStorage.getItem('userID');
};

export const getUserDisplayName = () => {
  let displayName = localStorage.getItem('userDisplayName') ? localStorage.getItem('userDisplayName').split('-') : ['Admin', 'Account'];
  // return displayName[0] + " " + displayName[1];
  return displayName[0];
};
export const getUserDisplayNameFirstS = (name) => {
  // Split the name into an array of words using space (' ') as the delimiter
  // For example, "John Doe" becomes ["John", "Doe"]
  const nameParts = name.split(' ');
  
  // Take the first character of the first word (first name)
  // and the first character of the second word (last name)
  // and join them with a space in between
  return nameParts[0] + ' ' + (nameParts.length > 1 ? nameParts[1][0] : '') +' '+ (nameParts.length > 2 ? nameParts[2][0] : '');
};
export const getFullUserDisplayName = () => {
  let displayName = localStorage.getItem('userDisplayName') ? localStorage.getItem('userDisplayName').split('-') : ['Admin', 'Account'];
   return displayName[0] + " " + displayName[1];
  };
export const setUserRole = (value) => {
  localStorage.setItem('role', value, { expires: sessionDaysToExpire });
};

export const getUserRole = () => {
  return localStorage.getItem('role');
};

export const setRememberLogin = (value) => {
  localStorage.setItem('rememberUser', value, { expires: 30 });
};
export const getRememberLogin = () => {
  return localStorage.getItem('rememberUser');
};

export const setUsername = (value) => {
  localStorage.setItem('username', value, { expires: sessionDaysToExpire });
};
export const setUserProfile = (value) => {
  localStorage.setItem('activeUserProfile', value, { expires: sessionDaysToExpire });
};
export const getUserProfile = () => {
  return JSON.parse(localStorage.getItem('activeUserProfile'));
};

export const getUsername = () => {
  return localStorage.getItem('username');
};
export const getDateRange = () => {
  moment.tz.setDefault('America/New_York');
  let today = moment().utc().format('ddd MMM DD YYYY hh:mm:ss [GMT]ZZ (zz)');
  const start = moment(today).startOf('month').toISOString();
  const end = moment(today).endOf('month').toISOString();
  const dateRange = {
    startDate: start,
    endDate: end,
  };
  return dateRange;
};
export const getDateRangeByInput = (inp) => {
  moment.tz.setDefault('America/New_York');
  let today = moment().utc().format('ddd MMM DD YYYY hh:mm:ss [GMT]ZZ (zz)');

  const existingDate = new Date(inp);
  const momentObj = moment(existingDate);

  const start = momentObj.startOf('month').toISOString();
  const end = moment(today).endOf('month').toISOString();
  const dateRange = {
    startDate: start,
    endDate: end,
  };
  return dateRange;
};
export const getDate = () => {
  moment.tz.setDefault('America/New_York');
  return moment.now();
};
export const getToday = () => {
  moment.tz.setDefault('America/New_York');
  return moment();
};
export const getDateFormatted = () => {
  moment.tz.setDefault('America/New_York');
  let today = moment().utc().format('ddd MMM DD YYYY hh:mm:ss [GMT]ZZ (zz)');

  return today;
};

export const getDateFormattedByInput = (inp) => {
  moment.tz.setDefault('America/New_York');
  let today = moment(inp).utc().format('ddd MMM DD YYYY hh:mm:ss [GMT]ZZ (zz)');

  return today;
};
export const getDateByInput = (inp) => {
  moment.tz.setDefault('America/New_York');
  return moment(inp);
};
export const getMonthNumber = (inp) => {
  try {
    moment.tz.setDefault('America/New_York');

    return moment().month(inp).format('M');
  } catch (error) {
    console.log(error)
  }

};
// export const setLocation = (value) => {
//   //   ReactGA.set({
//   //     dimension1: value.city,
//   //   });

//   //   ReactGA.set({
//   //     dimension2: value.province,
//   //   });

//   localStorage.setItem('location', JSON.stringify(value), { expires: 30 });
// };

// export const setAgeVerified = (value) => {
//   localStorage.setItem('ageVerified', value, { expires: 30 });
// };

// export const setRegion = (value) => {
//   localStorage.setItem('region', value, { expires: 30 });
// };

// // export const setRemember = () => {
// //   return localStorage.getItem("jwt");
// // };

export const getJWT = () => {
  return localStorage.getItem('jwt');
};

export const getRole = () => {
  return localStorage.getItem('role');
};

// export const getLocation = () => {
//   return localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : undefined;
// };

// export const getAgeVerified = () => {
//   return localStorage.getItem('ageVerified');
// };

// export const getRegion = () => {
//   return localStorage.getItem('region');
// };

// // export const getRemember = () => {
// //   return localStorage.getItem("jwt");
// // };

const removeJWT = () => {
  localStorage.removeItem('jwt');
};

export const removeUserID = () => {
  localStorage.removeItem('userID');
};
export const removeUserName = () => {
  localStorage.removeItem('username');
};
export const removeUserDisplayName = () => {
  localStorage.removeItem('userDisplayName');
};
export const removeJWTAndUserID = () => {
  removeJWT();
  removeUserID();
  removeUserName();
  removeUserDisplayName();
  localStorage.removeItem('role');
};

const exports = {
  adminID,
  setJWT,
  setUserID,
  setUserDisplayName,
  getUserDisplayName,
  getFullUserDisplayName,
  getRememberLogin,
  setRememberLogin,
  setUsername,
  getUsername,
  setUserRole,
  getUserRole,
  setUserProfile,
  getUserProfile,
  // setRole,
  // setLocation,
  // setAgeVerified,
  // setRegion,
  getJWT,
  getUserID,
  getRole,
  // getLocation,
  // getAgeVerified,
  // getRegion,
  removeJWTAndUserID,
  processenv,
  getDateRange,
  getDate,
  getToday,
  getDateFormatted,
  getDateFormattedByInput,
  getDateByInput,
  getDateRangeByInput,
  getMonthNumber,
  environment,
  // isMobileApp,
  app_version,
  getUserDisplayNameFirstS
};
export default exports;
