import React from 'react';
import Form from 'react-bootstrap/Form';
import { PRE_QUAL } from '../../utils/constants';
import Alert from 'react-bootstrap/Alert';

export default function ResidenceForm({ residenceType, handleChange, formData, handleTimeLived, validated, status }) {
  const { RESIDENTIAL_STATUS, PROVINCES, MARITAL_STATUS, NUMBER_OF_DEPENDENTS } = PRE_QUAL; //CONSTANTS for Form Options
  const residenceStatus = formData[`${residenceType}ResidentialStatus`];
  const timeAtResidence = {
    years: formData[`${residenceType}YearsAtResidence`],
    months: formData[`${residenceType}MonthsAtResidence`],
  };

  const isYearsNeeded = () => {
    if (!timeAtResidence.years && !timeAtResidence.months) return true;
    if (Number(timeAtResidence.months) === 0 && !timeAtResidence.years) return true;
    return false;
  };
  const isMonthsNeeded = () => {
    if (!timeAtResidence.years && !timeAtResidence.months) return true;
    if (Number(timeAtResidence.years) === 0 && !timeAtResidence.months) return true;
    return false;
  };

  return (
    <>
      <Form.Group className="mb-3" controlId={`${residenceType}ResidentialStatus`}>
        <Form.Label>I currently:</Form.Label>
        <Form.Select
          aria-label={`${residenceType} Residential Status`}
          onChange={handleChange}
          value={formData[`${residenceType}ResidentialStatus`]}
          required
          isInvalid={validated && !formData[`${residenceType}ResidentialStatus`]}
          readOnly={status.loading}
        >
          {RESIDENTIAL_STATUS.map((i) => (
            <option key={`${residenceType}prov-${i}`} value={i}>
              {i}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {(residenceStatus === 'Own' || residenceStatus === 'Rent') && (
        <Form.Group className="mb-3" controlId={`${residenceType}ResidenceCost`}>
          {residenceStatus === 'Rent' && <Form.Label>Monthly Payments</Form.Label>}
          {residenceStatus === 'Own' && <Form.Label>Monthly Mortgage Payment</Form.Label>}
          <div className="inputDollar-container">
            <span className="inputDollar-symbol">$</span>
            <Form.Control
              type="text"
              placeholder={`${residenceType[0].toUpperCase()}${residenceType.slice(1)} residence cost`}
              onChange={handleChange}
              value={formData[`${residenceType}ResidenceCost`]}
              required
              isInvalid={validated && !formData[`${residenceType}ResidenceCost`]}
              readOnly={status.loading}
            />
          </div>
        </Form.Group>
      )}

      <Form.Group className="mb-3" controlId={`${residenceType}Address`}>
        <Form.Label>Street</Form.Label>
        <Form.Control
          type="text"
          placeholder={`${residenceType[0].toUpperCase()}${residenceType.slice(1)} residence address`}
          onChange={handleChange}
          value={formData[`${residenceType}Address`]}
          required
          isInvalid={validated && !formData[`${residenceType}Address`]}
          readOnly={status.loading}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId={`${residenceType}AptNo`}>
        <Form.Label>Apartment, Suite, etc</Form.Label>
        <Form.Control
          type="text"
          placeholder={`${residenceType[0].toUpperCase()}${residenceType.slice(1)} apartment number`}
          onChange={handleChange}
          value={formData[`${residenceType}AptNo`]}
          // isInvalid={validated && !formData[`${residenceType}AptNo`]}
          readOnly={status.loading}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId={`${residenceType}City`}>
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          placeholder={`${residenceType[0].toUpperCase()}${residenceType.slice(1)} residence city`}
          onChange={handleChange}
          value={formData[`${residenceType}City`]}
          required
          isInvalid={validated && !formData[`${residenceType}City`]}
          readOnly={status.loading}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId={`${residenceType}Province`}>
        <Form.Label>Province</Form.Label>
        <Form.Select
          aria-label="Province"
          onChange={handleChange}
          value={formData[`${residenceType}Province`]}
          required
          isInvalid={validated && !formData[`${residenceType}Province`]}
          readOnly={status.loading}
        >
          {PROVINCES.map((i) => (
            <option key={`${residenceType}prov-${i}`} value={i}>
              {i}
            </option>
          ))}
        </Form.Select>
        {(formData[`${residenceType}Province`] === 'Yukon' || formData[`${residenceType}Province`] === 'Quebec' || formData[`${residenceType}Province`] === 'Northwest Territories') ? (<> <br></br>
          <Alert key={'danger'} variant={'danger'}>
            We're sorry, we are not yet able to lend in your province. Please check back often as we are working to lend across Canada.
          </Alert> </>) : ''}

      </Form.Group>

      <Form.Group className="mb-3" controlId={`${residenceType}PostalCode`}>
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="text"
          placeholder={`${residenceType[0].toUpperCase()}${residenceType.slice(1)} residence postal code`}
          onChange={handleChange}
          value={formData[`${residenceType}PostalCode`]}
          required
          isInvalid={
            validated && (formData[`${residenceType}PostalCode`].length < 6 || !formData[`${residenceType}PostalCode`])
          }
          readOnly={status.loading}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId={`${residenceType}TimeLived`}>
        <Form.Label>I’ve been here:</Form.Label>
        <span style={{ display: `flex` }}>
          <Form.Control
            className="yearsAtResidence"
            type="text"
            id={`${residenceType}YearsAtResidence`}
            aria-label={`Years lived here (${residenceType})`}
            placeholder="Years"
            onChange={(e) => handleTimeLived(e, `years`, residenceType)}
            readOnly={status.loading}
            value={formData[`${residenceType}YearsAtResidence`]}
            min={0}
            isInvalid={validated && isYearsNeeded()}
            required={isYearsNeeded()}
          />
          <Form.Control
            id={`${residenceType}MonthsAtResidence`}
            type="text"
            aria-label={`Months lived here (${residenceType})`}
            placeholder="Months"
            onChange={(e) => handleTimeLived(e, `months`, residenceType)}
            value={formData[`${residenceType}MonthsAtResidence`]}
            // required={Number(timeAtResidence.years) === 0 || reqMonths}
            isInvalid={validated && isMonthsNeeded()}
            required={isMonthsNeeded()}
            readOnly={status.loading}
            min={0}
          />
        </span>
      </Form.Group>


      <Form.Group className="mb-3" controlId={`maritalStatus`}>
        <Form.Label>Marital Status</Form.Label>
        <Form.Select
          aria-label="Marital Status"
          onChange={handleChange}
          value={formData[`maritalStatus`]}
          required
          isInvalid={validated && !formData[`maritalStatus`]}
          readOnly={status.loading}
        >
          <option key={`maritalStatus`} value={null}>
                            </option>
          {MARITAL_STATUS.map((i) => (
            <option key={`maritalStatus-${i}`} value={i}>
              {i}
            </option>
          ))}
        </Form.Select>
      </Form.Group>


      <Form.Group className="mb-3" controlId={`numberOfDependents`}>
        <Form.Label>Number of Dependents</Form.Label>
        <Form.Select
          aria-label="Number of Dependents"
          onChange={handleChange}
          value={formData[`numberOfDependents`]} 
          defaultValue={0}
          readOnly={status.loading}
        >
        
          {NUMBER_OF_DEPENDENTS.map((i) => (
            <option key={`numberOfDependents-${i}`} value={i}>
              {i}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

     {(formData[`numberOfDependents`] >= 0) ?
        (<Form.Group className="mb-3" controlId={`childSupportObligations`}>
          <Form.Label>Do you have any spousal or child support obligations (ongoing or past due)?</Form.Label>
          <Form.Select
            aria-label="Do you have any spousal or child support obligations (ongoing or past due)?"
            onChange={handleChange}
            value={formData[`childSupportObligations`]}
            required
            isInvalid={validated && !formData[`childSupportObligations`]}
            readOnly={status.loading}
          >
            <option key={`childSupportObligations`} value={null}>

            </option>
            <option key={`childSupportObligations`} value={'true'}>
              Yes
            </option>
            <option key={`childSupportObligations`} value={'false'}>
              No
            </option>

          </Form.Select>
        </Form.Group>)
        : ''}


      {(formData[`numberOfDependents`] >= 0 && (formData[`childSupportObligations`] === 'true' || formData[`childSupportObligations`] === true)) ?
        (<Form.Group className="mb-3" controlId={`childSupportObligationsAmount`}>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="tel"
            placeholder={`Amount`}
            onChange={handleChange}
            value={formData[`childSupportObligationsAmount`]}
            required
            isInvalid={validated && !formData[`childSupportObligationsAmount`]}
            readOnly={status.loading}
          />
        </Form.Group>)
        : ''}



    </>
  );
}
