/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import api from '../../../utils/api';
import { compareNames, formatDateForDisplay, parseIdFromLink } from '../../../utils/helpers';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isValid from 'date-fns/isValid';
// import getYear from 'date-fns/getYear';
import isSameMonth from 'date-fns/isSameMonth';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { MONTH_NUM_TO_NAME, USER_TYPE } from '../../../utils/constants';
import cookies from '../../../utils/cookies';
export default function Index() {
  //===================================================Date handling

  const today = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(MONTH_NUM_TO_NAME[today.month() + 1]);
  const [selectedUserType, setSelectedUserType] = useState('USER');
  const [selectedYear, setSelectedYear] = useState(today.year());
  const [quizzesForMonth, setQuizzesForMonth] = useState(null);
  const [streakData, setStreakData] = useState(null);

  const [status, setStatus] = useState({ loading: true, error: null });
  const selectedYearString = `${selectedYear}`;
  const dateString = `15 ${selectedMonth} ${selectedYear}`; //15th in string is arbitrary, time is midnight EST

  const handleViewMonth = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
  };

  const handleViewUserType = (e) => {
    const value = e.target.value;
    setSelectedUserType(value);
  };

  const handleYear = (e) => {
    const value = e.target.value;
    if (value.length > 4) return;
    setSelectedYear(value);
  };

  useEffect(() => {
    setQuizzesForMonth(null);
    setStreakData(null);
    const getQuizzesForMonth = async (dateRange) => {
      setStatus({ loading: true, error: null });
      try {
        const rawRes = await api.getQuizzesByDatesByUserType(dateRange, selectedUserType);
        const monthsQuizzes = rawRes.data._embedded.quizzes.sort((x, y) =>
          x.availableDate.localeCompare(y.availableDate)
        );
        setQuizzesForMonth(monthsQuizzes);
        // setStatus({ loading: false, error: null });
      } catch (e) {
        setStatus({ loading: false, error: `${e}` });
      }
    };
    /* Update date range to view */
    const date = new Date(dateString);
    let currDateRange = null;
    if (selectedYearString.length === 4 && isValid(date)) {
      currDateRange = {
        startDate: startOfMonth(date).toISOString(),
        endDate: endOfMonth(date).toISOString(),
      };
    }

    // alert(JSON.stringify(currDateRange))
    currDateRange && getQuizzesForMonth(currDateRange);
  }, [selectedMonth, selectedYear, selectedYearString, selectedUserType, dateString]);

  //================================================================User handling
  const [users, setUsers] = useState(null);

  useEffect(() => {
    //Get All users, limit to 25 pagination
    //[] Should it update when the dates change? like a refresh?



    const getAndSetAllUsers = async () => {
      try {

        // let roleType = 'ROLE_USER';
        // switch (selectedUserType) {
        //   case 'USER':
        //     roleType = 'ROLE_USER';
        //     break;
        //   case 'FREEMIUM':
        //     roleType = 'ROLE_FREEMIUM';
        //     break;
        //   case 'EMPLOYEE':
        //     roleType = 'ROLE_EMPLOYEE';
        //     break;
        //   default:
        //     roleType = 'ROLE_USER';
        //     break;
        // }


        // const rawUsersRes = await api.getAllUsersSortByfirstNameByPage();
        // const initUsers = rawUsersRes.data._embedded.users.filter((x) => x.role.name === roleType);

        let rawUsersRes = await api.findAllByUserType(500, 0, selectedUserType);
        const initUsers = rawUsersRes.data.content;



        setUsers(initUsers);
      } catch (e) {
        console.error(e);
      }
    };
    getAndSetAllUsers();
  }, [selectedUserType, selectedMonth, selectedYear, quizzesForMonth, dateString,]);
  const [selectedOption, setSelectedOption] = useState('all');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  //================================================================User Quiz Answer handling
  useEffect(() => {
    setStreakData(null);
    setStatus({ loading: true, error: null });
    //Get Users Answers
    const getAndSetUserAnswers = async () => {
      try {
        const monthEnd = endOfMonth(new Date(dateString));
        // Filter Users - only want those that have 'active' loans (ref loan start)
        // const filteredUsers = users.filter((x) => {
        //   const diff = differenceInCalendarDays(new Date(x.loanStartDate), monthEnd);
        //   const isLoanActive = diff <= 0;
        //   return x.loanStartDate ? isLoanActive : false;
        // });

        const filteredUsers = users.filter((x) => {
          const diff = differenceInCalendarDays(new Date(x.loanStartDate), monthEnd);
          let isTestAccount = cookies.processenv.REACT_APP_ENV === 'api.prod' && x.firstName === 'FE Test';

          let filterItem = !isTestAccount && x.loanStartDate ? diff <= 0 : false;
          if (selectedOption === 'all') {
            //do nothing
          } else if (selectedOption === 'enabled') {
            filterItem = x.enabled === true ? true : false;
          } else if (selectedOption === 'disabled') {
            filterItem = x.enabled === false ? true : false;
          } else {
            //do nothing
          }
          return filterItem;
        });

        const rawAllUserAnswers = await Promise.all(
          filteredUsers.map(async (u) => {
            let self = `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}/users/`
            const userID = parseIdFromLink(self + u.id);
            return api.getAllUsersQuizAnswers(userID);
          })
        );
        const allUserAnswers = rawAllUserAnswers.map((y) => y.data._embedded.quizAnswers);
        const newUserState = [...filteredUsers];
        allUserAnswers.forEach((userAnswers, i) => {
          newUserState[i] = {
            ...newUserState[i],
            answers: userAnswers?.filter((ans) => isSameMonth(new Date(ans.quiz.availableDate), new Date(dateString))), //Answers for currMonth only
          };
        });
        const newState = newUserState.map((x) => {
          const streak = quizzesForMonth.map((q) => {
            const quizID = parseIdFromLink(q._links.self.href);
            const usersAns =
              x.answers.length > 0
                ? x.answers.find((x) => {
                  const answerID = parseIdFromLink(x.quiz._links.self.href.replace('{?projection}', ''));
                  if (answerID === quizID) return true;
                  return false;
                })
                : null;
            const isCorrect = usersAns?.answer === q.correctAnswer;
            return {
              question: q.question,
              userAnswer: usersAns?.answer,
              isCorrect,
              dateAvailable: q.availableDate,
              dateAnswered: usersAns?.createDateTime,
            };
          });
          return { ...x, streak: streak };
        });

        const usersWithAnswers = newState.filter((x) => x.streak.some((y) => y.userAnswer)).sort(compareNames); //.filter((x) => x.streak.some((y) => y.userAnswer))
        const noAnswers = newState.filter((x) => !x.answers.length).sort(compareNames); //.filter((x) => x.streak.some((y) => y.userAnswer))
        const dataToDisplay = quizzesForMonth.length > 0 ? [...usersWithAnswers, ...noAnswers] : []; //Show streak complete, then answers, then no answers

        // Sort the array by firstname in alphabetical order
        dataToDisplay.sort((a, b) => a.firstName.localeCompare(b.firstName));

        setStreakData(dataToDisplay); //Only displays users that answers some of the months questions
        setStatus({ loading: false, error: null });
      } catch (e) {
        setStatus({ loading: false, error: `${e}` });
      }
    };
    users && quizzesForMonth?.length > 0
      ? getAndSetUserAnswers()
      : setStatus({ loading: false, error: 'No quizzes for this month' });
  }, [users, quizzesForMonth, dateString, selectedUserType, selectedOption]);



  //================================================================
  return (
    <div>
      <div>
        <h1>{`Quiz Streaks`}</h1>

        <h2>Select Month to view</h2>
        <div>
          <Form.Select
            id="quizMonth"
            className="mr-8"
            aria-label="Month to view"
            onChange={handleViewMonth}
            value={selectedMonth}
          >
            {Object.values(MONTH_NUM_TO_NAME)?.map((i) => (
              <option key={`dobM-${i}`} value={i}>
                {i}
              </option>
            ))}
          </Form.Select>
          <Form.Control value={selectedYear} type="number" onChange={handleYear} />
        </div>

        <hr />
        <h2>Select User Type to view</h2>
        <div>
          <Form.Select
            id="userType"
            className="mr-8"
            aria-label="User Type to view"
            onChange={handleViewUserType}
            value={selectedUserType}
          >
            {Object.values(USER_TYPE)?.map((i) => (
              <option key={`userType-${i}`} value={i}>
                {i === 'USER' ? 'CUSTOMER' : i}
              </option>
            ))}
          </Form.Select>
        </div>

      </div>
      <br />
      <Table striped bordered hover size="sm">
        <caption>
          {status.loading && <p>{status.loading && 'Loading...'}</p>}
          {status.error && <p className="error">{status.error}</p>}
          <p>✅ = Correct ❌ = Wrong Answer ⚪ = No Answer</p>
        </caption>
        <thead>
          <tr>
            <th colSpan="6">{`Filter by enabled/disable`}
              <hr></hr>
              <div>
                <label>
                  <input
                    type="radio"
                    value="all"
                    checked={selectedOption === 'all'}
                    onChange={handleOptionChange}
                  />
                  &nbsp; &nbsp;all
                </label>
                &nbsp; &nbsp;
                <label>
                  <input
                    type="radio"
                    value="enabled"
                    checked={selectedOption === 'enabled'}
                    onChange={handleOptionChange}
                  />
                  &nbsp; &nbsp;enabled
                </label>
                &nbsp; &nbsp;
                <label>
                  <input
                    type="radio"
                    value="disabled"
                    checked={selectedOption === 'disabled'}
                    onChange={handleOptionChange}
                  />
                  &nbsp; &nbsp;disabled
                </label>
                <hr></hr>
                <div>Selected option: {selectedOption}</div>
              </div>

            </th>
          </tr>
          <tr>
            <th>User ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
            <th>Responses</th>
            <th>{`All months quizzes (${quizzesForMonth?.length}) answered correctly`}</th>
          </tr>
        </thead>

        <tbody>
          {streakData &&
            streakData.map((u, i) => {
              return (


                <>
                  {/* <p><div><pre>{ JSON.stringify(inspirationsList, null, 2) }</pre></div></p> */}
                  <tr key={`streakUser-${i}`}>
                    {/* <td>{parseIdFromLink(u._links.self.href)}</td> */}
                    <td>{u.id}</td>
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td>{u.username}</td>
                    {/* For streak column show correct answers / wrong ansers / no response */}
                    <td>
                      {u.streak?.map((x, i) => {
                        const popover = (
                          <Popover id="popover-basic">
                            <Popover.Header
                              as="h3"
                              style={{ backgroundColor: `${x.isCorrect ? 'lightgreen' : 'lightcoral'}` }}
                            >{`Question: ${x.question} - ${formatDateForDisplay(x.dateAvailable)}`}</Popover.Header>
                            <Popover.Body>
                              <p>{`Response: ${x.userAnswer}`}</p>
                              <p>{`Date Answered: ${formatDateForDisplay(x.dateAnswered, { dateTime: true })}`}</p>
                            </Popover.Body>
                          </Popover>
                        );

                        if (x.userAnswer === undefined) return <span style={{ padding: '4px' }}>⚪</span>;
                        const streakItem = (
                          <OverlayTrigger key={`streakPop-${i}`} overlay={popover}>
                            <span style={{ padding: '4px' }}>{x.isCorrect === true ? '✅' : '❌'}</span>
                          </OverlayTrigger>
                        );
                        return streakItem;
                      })}
                    </td>
                    <td>
                      {quizzesForMonth?.length > 0 && quizzesForMonth?.length === u?.streak?.length
                        ? u.streak.every((x) => x.isCorrect === true)
                          ? `Yes - ${u?.streak?.length}/${quizzesForMonth?.length}`
                          : null
                        : 'No Quizzes'}
                    </td>
                  </tr>

                </>
              );
            })}
        </tbody>
        {streakData && streakData.length === 0 && (
          <caption>{`No results Found for ${selectedMonth} ${selectedYear}`}</caption>
        )}
      </Table>
    </div>
  );
}
