import { removeCommas } from '../../utils/helpers';
import { ABBREVIATED_PROVINCES } from '../../utils/constants';

// eslint-disable-next-line
const examplePreQualData = {
  client: {
    debtToConsolidate: '10000',
    firstName: 'John',
    lastName: 'Smith',
    email: '1@1.ca',
  },
  clientPhone: 5555555555, //Saves after OTP is complete > currently not used in API
  income: {
    /* Only Employed has extra fields (name, title, work # ) */
    primaryIncomeType: 'Employed',
    primaryGrossIncome: '25000',
    primaryEmployerName: 'BK',
    primaryJobTitle: 'Flipper',
    primaryWorkPhone: '5555551234',
    primaryHiredDay: '12',
    primaryHiredMonth: 'Jan',
    primaryHiredYear: '1999',
    /* Secondary Is Optional */
    secondaryIncomeType: 'Employed',
    secondaryGrossIncome: '25000',
    secondaryEmployerName: 'McD',
    secondaryJobTitle: 'Frie Dipper',
    secondaryWorkPhone: '55555559874',
    secondaryHiredDay: '12',
    secondaryHiredMonth: 'Jan',
    secondaryHiredYear: '1999',
    residencyStatus: 'Canadian Citizen',
    creditScore: '661 to 780',
    scoreSource: 'My Bank',
    dobMonth: 'Jun',
    dobDay: '7',
    dobYear: '1995',
    hasBankrupcy: false,
    hasProvID: true,
    hasPassport: true,

    outstandingPaydayLoans: false,
    unpaidIncomeTaxes: false
  },
  residence: {
    primaryResidentialStatus: 'Own',
    /* Owning asks for mortgage payments*/
    primaryResidenceCost: '1000',
    primaryAddress: '123 Main St',
    // primaryAptNo: '32',
    primaryCity: 'Place',
    primaryProvince: 'Ontario',
    primaryPostalCode: 'N3L3L4',
    primaryYearsAtResidence: '0',
    primaryMonthsAtResidence: '11',
    /* If < 1 year @ residence, a 2nd is asked */
    previousResidentialStatus: 'Rent',
    previousResidenceCost: '1000',
    previousAddress: '321 Place Pl.',
    // previousAptNo: '32',
    previousCity: 'Somewhere',
    previousPostalCode: 'R1A 2A8',
    previousProvince: 'Manitoba',
    previousYearsAtResidence: '5',
    previousMonthsAtResidence: '12',
  },
  wellnessQuestions: {
    q0: {
      question: 'Because of my money situation, I feel like I will never have the things I want in life.',
      answer: 'Strongly agree',
    },
    q1: {
      question: 'I am just getting by financially.',
      answer: 'Don’t agree or disagree',
    },
    q2: {
      question: 'I am concerned that the money I have or will save won’t last.',
      answer: 'Don’t agree or disagree',
    },
    q3: {
      question: 'I have money left over at the end of the month.',
      answer: 'Somewhat agree',
    },
    q4: {
      question: 'My finances control my life.',
      answer: 'Somewhat agree',
    },
  },
  termsAccepted: true,
  /* ===================BELOW IS ADDED AFTER INITIAL SUBMISSION (pending or ready/approved)============= */
  /* SUBMISSION REQUIRES A RETURN OF
    -status: approved (ready), pending (not ready), notApproved
    - if approved, `preQualAmount` //Amount of $ they are qualified for
    */
  debtToConsolidate: [
    /* No limit on types */
    {
      amount: '5000',
      monthlyPayments: '200',
      interestRate: '20',
      creditor: 'Visa',
      type: 'Credit card',
    },
    {
      amount: '1000',
      monthlyPayments: '10',
      interestRate: '20',
      creditor: 'Master Card',
      type: 'Credit card',
    },
    {
      amount: '5000',
      monthlyPayments: '50',
      interestRate: '8',
      creditor: 'Bank',
      type: 'Line of credit',
    },
  ],
  nonConsolidatedDebt: [
    /* No limit on types */
    {
      amount: '100000',
      monthlyPayments: '1000',
      // interestRate: '5',
      creditor: 'Mort',
      type: 'Mortgage',
    },
    {
      amount: '20000',
      monthlyPayments: '300',
      // interestRate: '5',
      creditor: 'VehiclePlace',
      type: 'Vehicle Loan',
    },
  ],

  /* ===================BELOW ONLY EXIST IF KNOCKOUT OCCURS============= */
  behaviourQuestions: {
    q0: {
      question: 'Thinking about new plans for my life is an easy task for me.',
      answer: 'Strongly agree',
    },
    q1: {
      question: 'I lean on the people around me when I am faced with change.',
      answer: 'Don’t agree or disagree',
    },
    q2: {
      question: 'When I am faced with change, I can see things from multiple points of view.',
      answer: 'Don’t agree or disagree',
    },
    q3: {
      question: 'I am always keeping my eye open for new opportunities in life.',
      answer: 'Somewhat agree',
    },
    q4: {
      question: 'I find it easy to accept and open myself up to change.',
      answer: 'Somewhat agree',
    },
    q5: {
      question: 'My opinions have changed over the years. ',
      answer: 'Somewhat agree',
    },
  },
  financialLiteracyQuestions: {
    q0: {
      question:
        'You never have to report interest and profits gained in your Tax-Free Savings Account (TFSA) when filing your taxes.',
      answer: 'True',
    },
    q1: {
      question: 'I lean on the people around me when I am faced with change.',
      answer: 'True',
    },
    q2: {
      question: 'A ‘mortgage term’ refers to the length of time it will take to pay off your mortgage.',
      answer: 'True',
    },
    q3: {
      question: 'You must pay for government insurance on a mortgage where a down payment of less than 20% is made.',
      answer: 'False',
    },
  },
};

const handleYearsAtResidence = (input) => {
  /* Input will get a string, convert to a #, only return max of 10 */
  if (input === null || input === undefined) return input;
  const convertedNum = Number(input);
  if (convertedNum > 10) return 10;
  return convertedNum;
};

const convertToMonthNum = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export const formatDataForAPI = (inputData) => {
  /* Match inputData to api format
    NOTE, patches require debt0-7 */

  const data = inputData;
  // const checkElegibilityCriteria = (isChecks) => {
  //   let isEligible = true;
  //   for (let x in isChecks) {
  //     if (isChecks[x] === false) {
  //       isEligible = false;
  //     }
  //   }
  //   return isEligible;
  // };

  // alert(data?.client?.debtToConsolidate);
  const apiFormat = {
    appID: data?.appID,
    //ReferenceInfo
    initial_utm_source: data?.initial_utm_source,
    initial_utm_campaign: data?.initial_utm_campaign,
    initial_utm_medium: data?.initial_utm_medium,
    clickId: data?.click_id,
    hubspotID: data?.hubspotID, // Only send in POST requests, PATCH's will remove this field @ the api file (due to Hubspot overwrites)

    /* Form 1 - User Info */

    debtToConsolidate: removeCommas(String(data?.client?.debtToConsolidate)) !== 'undefined' ? removeCommas(String(data?.client?.debtToConsolidate)) : 0,
    firstName: data?.client?.firstName,
    lastName: data?.client?.lastName,
    email: data?.client?.email?.toLowerCase(),

    ELIGIBILITY_CRITEREIA: data?.client?.ELIGIBILITY_CRITEREIA,
    ELIGIBILITY_CRITEREIA_CHECKED: data?.client?.ELIGIBILITY_CRITEREIA_CHECKED,
    eligibilityCriteria1: data?.client?.eligibilityCriteria1,
    eligibilityCriteria2: data?.client?.eligibilityCriteria2,
    eligibilityCriteria3: data?.client?.eligibilityCriteria3,
    consumerProposal: data?.client?.consumerProposal,

    highInterestCreditCardDebt: data?.client?.highInterestCreditCardDebt,
    highInterestLoanDebt: data?.client?.highInterestLoanDebt,
    consolidateDebt: data?.client?.consolidateDebt,
    // highInterestCreditCardAndLoanDebt: data?.client?.highInterestCreditCardAndLoanDebt,

    //Client Phone
    phone: data.clientPhone,
    preferredTime: data.preferredTime,

    /* Form 2 - Income and Residency (Citizenship) */
    applicantIncome: {
      incomeType: data?.income?.primaryIncomeType,
      grossIncome: removeCommas(data?.income?.primaryGrossIncome),
      employerName: data?.income?.primaryEmployerName,
      jobTitle: data?.income?.primaryJobTitle,
      workPhone: data?.income?.primaryWorkPhone,
      //[] DRY START DATEs
      startDate:
        data?.income?.primaryHiredMonth && data?.income?.primaryHiredDay && data?.income?.primaryHiredYear
          ? `${convertToMonthNum[data?.income?.primaryHiredMonth]}/${data?.income?.primaryHiredDay}/${data?.income?.primaryHiredYear
          }`
          : null,
    },
    applicantIncome2: {
      incomeType: data?.income?.secondaryIncomeType,
      grossIncome: removeCommas(data?.income?.secondaryGrossIncome),
      employerName: data?.income?.secondaryEmployerName,
      jobTitle: data?.income?.secondaryJobTitle,
      workPhone: data?.income?.secondaryWorkPhone,
      startDate:
        data?.income?.secondaryHiredMonth && data?.income?.secondaryHiredDay && data?.income?.secondaryHiredYear
          ? `${convertToMonthNum[data?.income?.secondaryHiredMonth]}/${data?.income?.secondaryHiredDay}/${data?.income?.secondaryHiredYear
          }`
          : null,
    },
    applicantIncome3: {
      // employerName: null,
      // grossIncome: null,
      // incomeType: null,
      // jobTitle: null,
      // workPhone: null,
    },
    applicantIncome4: {
      // employerName: null,
      // grossIncome: null,
      // incomeType: null,
      // jobTitle: null,
      // workPhone: null,
    },
    applicantIncome5: {
      // employerName: null,
      // grossIncome: null,
      // incomeType: null,
      // jobTitle: null,
      // workPhone: null,
    },
    residencyStatus: data?.income?.residencyStatus, //Citizenship
    creditScore: data?.income?.creditScore,
    scoreSource: data?.income?.scoreSource,
    dobDay: data?.income?.dobDay,
    dobMonth: data?.income?.dobMonth,
    dobYear: data?.income?.dobYear,
    hasBankruptcy: data?.income?.hasBankrupcy,
    hasProvID: data?.income?.hasProvID,
    hasPassport: data?.income?.hasPassport,

    outstandingPaydayLoans: data?.income?.outstandingPaydayLoans,
    unpaidIncomeTaxes: data?.income?.unpaidIncomeTaxes,

    maritalStatus: data?.residence?.maritalStatus,
    numberOfDependents: parseInt((data?.residence?.numberOfDependents === null || data?.residence?.numberOfDependents === 'null') ? 0 : data?.residence?.numberOfDependents),
    childSupportObligations: data?.residence?.childSupportObligations,
    childSupportObligationsAmount: (data?.residence?.childSupportObligationsAmount === null || data?.residence?.childSupportObligationsAmount === 'null') ? 0 : data?.residence?.childSupportObligationsAmount,




    /* Form 3 - Residence (home) */
    primaryResidenceCost: removeCommas(data?.residence?.primaryResidenceCost),
    applicantResidency: {
      residentialStatus: data?.residence?.primaryResidentialStatus,
      street: data?.residence?.primaryAddress, //Last minute change, manually mapped to match DB, ideally db will by updated
      aptNo: data?.residence?.primaryAptNo,
      city: data?.residence?.primaryCity,
      /* Convert Full province name to 2 ltr abbreviation */
      province: ABBREVIATED_PROVINCES[data?.residence?.primaryProvince],
      postalCode: data?.residence?.primaryPostalCode,
      yearsAtResidence: handleYearsAtResidence(data?.residence?.primaryYearsAtResidence),

      monthsAtResidence: Number(data?.residence?.primaryMonthsAtResidence) || null,
    },
    previousResidenceCost: removeCommas(data?.residence?.previousResidenceCost),
    applicantResidency2: {
      residentialStatus: data?.residence?.previousResidentialStatus,
      street: data?.residence?.previousAddress, //Last minute change, manually mapped to match DB, ideally db will by updated
      aptNo: data?.residence?.previousAptNo,
      city: data?.residence?.previousCity,
      /* Convert Full province name to 2 ltr abbreviation */
      province: ABBREVIATED_PROVINCES[data?.residence?.previousProvince],
      postalCode: data?.residence?.previousPostalCode,
      yearsAtResidence: handleYearsAtResidence(data?.residence?.previousYearsAtResidence),
      monthsAtResidence: Number(data?.residence?.previousMonthsAtResidence) || null,
    },

    /* Wellness survery */
    wellnessQuestionsA0: data?.wellnessQuestions?.q0?.answer,
    wellnessQuestionsA1: data?.wellnessQuestions?.q1?.answer,
    wellnessQuestionsA2: data?.wellnessQuestions?.q2?.answer,
    wellnessQuestionsA3: data?.wellnessQuestions?.q3?.answer,
    wellnessQuestionsA4: data?.wellnessQuestions?.q4?.answer,
    wellnessQuestionsA5: data?.wellnessQuestions?.q5?.answer,
    wellnessQuestionsA6: data?.wellnessQuestions?.q6?.answer,

    /* ToS */
    termsAccepted: data?.termsAccepted,
    freeFormQuestion: data?.freeFormQuestion?.freeFormQuestion,
    /* Debt To Consolidate */
    /* Non conolidated debt */
    /* Special Case, only 8 slots between consolidated and nonConsolidated debt
    It needs to be dynamic ie 4:4, 1:7 5:3, max 8 of either, or less
    Populating API format will need to occur before the return by iterating over debtToCondolidate
    validation for ratio will be at the form level
    >>> make a debt array in state, then use the new 'consolidated' flag to differentiate, max 8 in the array.
     */
    debt: {
      amount: removeCommas(data?.debt?.[0]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[0]?.monthlyPayments),
      interestRate: data?.debt?.[0]?.interestRate,
      creditor: data?.debt?.[0]?.creditor,
      type: data?.debt?.[0]?.type,
      needsConsolidate: data?.debt?.[0]?.needsConsolidate,
    },
    debt2: {
      amount: removeCommas(data?.debt?.[1]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[1]?.monthlyPayments),
      interestRate: data?.debt?.[1]?.interestRate,
      creditor: data?.debt?.[1]?.creditor,
      type: data?.debt?.[1]?.type,
      needsConsolidate: data?.debt?.[1]?.needsConsolidate,
    },
    debt3: {
      amount: removeCommas(data?.debt?.[2]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[2]?.monthlyPayments),
      interestRate: data?.debt?.[2]?.interestRate,
      creditor: data?.debt?.[2]?.creditor,
      type: data?.debt?.[2]?.type,
      needsConsolidate: data?.debt?.[2]?.needsConsolidate,
    },
    debt4: {
      amount: removeCommas(data?.debt?.[3]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[3]?.monthlyPayments),
      interestRate: data?.debt?.[3]?.interestRate,
      creditor: data?.debt?.[3]?.creditor,
      type: data?.debt?.[3]?.type,
      needsConsolidate: data?.debt?.[3]?.needsConsolidate,
    },
    debt5: {
      amount: removeCommas(data?.debt?.[4]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[4]?.monthlyPayments),
      interestRate: data?.debt?.[4]?.interestRate,
      creditor: data?.debt?.[4]?.creditor,
      type: data?.debt?.[4]?.type,
      needsConsolidate: data?.debt?.[4]?.needsConsolidate,
    },
    debt6: {
      amount: removeCommas(data?.debt?.[5]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[5]?.monthlyPayments),
      interestRate: data?.debt?.[5]?.interestRate,
      creditor: data?.debt?.[5]?.creditor,
      type: data?.debt?.[5]?.type,
      needsConsolidate: data?.debt?.[5]?.needsConsolidate,
    },
    debt7: {
      amount: removeCommas(data?.debt?.[6]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[6]?.monthlyPayments),
      interestRate: data?.debt?.[6]?.interestRate,
      creditor: data?.debt?.[6]?.creditor,
      type: data?.debt?.[6]?.type,
      needsConsolidate: data?.debt?.[6]?.needsConsolidate,
    },
    debt8: {
      amount: removeCommas(data?.debt?.[7]?.amount),
      monthlyPayments: removeCommas(data?.debt?.[7]?.monthlyPayments),
      interestRate: data?.debt?.[7]?.interestRate,
      creditor: data?.debt?.[7]?.creditor,
      type: data?.debt?.[7]?.type,
      needsConsolidate: data?.debt?.[7]?.needsConsolidate,
    },
    /* Knockout */
    // knockedOut: data?.knockedOut ? data?.knockedOut : null, //Flag to indicate the app was knocked out rather than abandoned
    knockedOut: (data?.knockedOut && (data?.knockedOut === true || data?.knockedOut === 'true')) ? true : false,
    behaviourQuestionsA0: data?.behaviourQuestions?.q0?.answer,
    behaviourQuestionsA1: data?.behaviourQuestions?.q1?.answer,
    behaviourQuestionsA2: data?.behaviourQuestions?.q2?.answer,
    behaviourQuestionsA3: data?.behaviourQuestions?.q3?.answer,
    behaviourQuestionsA4: data?.behaviourQuestions?.q4?.answer,
    behaviourQuestionsA5: data?.behaviourQuestions?.q5?.answer,
    behaviourQuestionsA6: data?.behaviourQuestions?.q6?.answer,

    financialLiteracyQuestionsA0: data?.financialLiteracyQuestions?.q0?.answer,
    financialLiteracyQuestionsA1: data?.financialLiteracyQuestions?.q1?.answer,
    financialLiteracyQuestionsA2: data?.financialLiteracyQuestions?.q2?.answer,
    financialLiteracyQuestionsA3: data?.financialLiteracyQuestions?.q3?.answer,
    financialLiteracyQuestionsA4: data?.financialLiteracyQuestions?.q4?.answer,
    financialLiteracyQuestionsA5: data?.financialLiteracyQuestions?.q5?.answer,
    financialLiteracyQuestionsA6: data?.financialLiteracyQuestions?.q6?.answer,
  };

  /* Remove 2nd residence fields if they are empty */
  if (Object.values(apiFormat.applicantResidency2).every((x) => !x)) {
    delete apiFormat['applicantResidency2'];
    if (apiFormat.previousResidenceCost === undefined) delete apiFormat['previousResidenceCost'];
  }
  return apiFormat;
};
