import React, { useState, useEffect } from "react";
import CardContainer from "../CardContainer";
import WideBtn from "../WideBtn";
import icon from "../../assets/Logo_Icon.svg";
import Form from "react-bootstrap/Form";
import ResidenceForm from "./ResidenceForm";
import TextButton from "../TextButton";
import { validateAndFormat } from "./validatePreQual";
import Spinner from "react-bootstrap/Spinner";
// import InfoBubble from "./InfoBubble";

export default function ResidenceDetails({
  navForm,
  updatePreQualState,
  stateData,
  status,
}) {


  const customerReferenceIdData = JSON.parse(localStorage.getItem('customerReferenceIdData'));
  let primaryResidenceCost="";
  try {
    primaryResidenceCost=stateData?.primaryResidenceCost || customerReferenceIdData?.primaryResidenceCost?customerReferenceIdData?.primaryResidenceCost.toString():"" || ""
    
  } catch (error) {
    
  }

  const [formData, setFormData] = useState({
    /* Owning asks for mortgage payments*/
    /* If < 1 year @ residence, a 2nd is asked */
    primaryResidentialStatus: stateData?.primaryResidentialStatus || customerReferenceIdData?.applicantResidency?.residentialStatus || "",
    primaryResidenceCost: primaryResidenceCost,
    primaryAddress: stateData?.primaryAddress || customerReferenceIdData?.applicantResidency?.street || "",
    primaryAptNo: stateData?.primaryAptNo || customerReferenceIdData?.applicantResidency?.aptNo || "",
    primaryCity: stateData?.primaryCity || customerReferenceIdData?.applicantResidency?.city || "",
    primaryProvince: stateData?.primaryProvince || customerReferenceIdData?.applicantResidency?.province || "",
    primaryPostalCode: stateData?.primaryPostalCode || customerReferenceIdData?.applicantResidency?.postalCode || "",
    primaryYearsAtResidence: stateData?.primaryYearsAtResidence || customerReferenceIdData?.applicantResidency?.yearsAtResidence || "",
    primaryMonthsAtResidence: stateData?.primaryMonthsAtResidence || customerReferenceIdData?.applicantResidency?.monthsAtResidence || "",
    previousResidentialStatus: stateData?.previousResidentialStatus || customerReferenceIdData?.applicantResidency?.residentialStatus || "",
    previousResidenceCost: stateData?.previousResidenceCost || customerReferenceIdData?.applicantResidency?.previousResidenceCost || "",
    previousAddress: stateData?.previousAddress || "",
    previousAptNo: stateData?.previousAptNo || "",
    previousCity: stateData?.previousCity || "",
    previousPostalCode: stateData?.previousPostalCode || "",
    previousProvince: stateData?.previousProvince || "",
    previousYearsAtResidence: stateData?.previousYearsAtResidence || "",
    previousMonthsAtResidence: stateData?.previousMonthsAtResidence || "",
   
    maritalStatus: stateData?.maritalStatus ||  null,
    numberOfDependents: stateData?.numberOfDependents ||  null,
    childSupportObligations: stateData?.childSupportObligations ||  null,
    childSupportObligationsAmount: stateData?.childSupportObligationsAmount ||  null,
    freeFormQuestion: stateData?.freeFormQuestion||  null,

  });

  useEffect(() => {
    stateData && setFormData({ ...stateData });
  }, [stateData]);

  const [validated, setValidated] = useState(false);
  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    // import { validateAndFormat } from './validatePreQual';
    const formattedValue = validateAndFormat("residence", key, value);
    if (formattedValue === undefined || formattedValue === null) return;
    const prevState = { ...formData };

    /* Clear cost if residence status is live with parents */
    if (
      key === "primaryResidentialStatus" ||
      key === "previousResidentialStatus"
    ) {
      if (value === "Live with parents or legal guardian") {
        //Clear applicable monthly cose
        if (key.includes("primary")) {
          prevState.primaryResidenceCost = "";
        }
        if (key.includes("previous")) {
          prevState.previousResidenceCost = "";
        }
      }
    }

    const newData = {
      ...prevState,
      [key]: formattedValue,
    };
    
    console.log(JSON.stringify(newData))
    setFormData(newData);
    // setFormData((p) => {
    //   const newData = { ...p };
    //   newData[key] = formattedValue;
    //   return newData;
    // });
  };

  const handleTimeLived = (e, field, ResType) => {
    e.preventDefault();
    const value = e.target.value;
    let key = ``;
    switch (field) {
      case `months`:
        key = `${ResType}MonthsAtResidence`;
        break;
      case `years`:
        key = `${ResType}YearsAtResidence`;
        break;
      default:
    }

    const formattedValue = validateAndFormat("residence", key, value);

    if (formattedValue === undefined || formattedValue === null) return;
    key &&
      setFormData((p) => {
        const newData = { ...p };
        newData[key] = formattedValue;
        return newData;
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    const formElement = e.target.innerHTML;
    const formContainsInvalidField = formElement.includes("is-invalid");
    const PREQUAL_STATE_KEY = "residence";
    if (validated && formContainsInvalidField !== true)
      return updatePreQualState(PREQUAL_STATE_KEY, formData);
  };

  useEffect(() => {
    if (validated === true) {
      handleSubmit({
        target: window.document.getElementsByClassName("applyForm")[0],
        preventDefault: () => { },
      });
    }
    //eslint-disable-next-line
  }, [validated]);

  /* 
  - Time lived, can calc months into years, ie 15 month = 1 yr, 3months
  - can also check and set requireSecondary during the change 
  */
  const needPrevRes = () => {
    //[] TODO need to DRY
    if (
      formData.primaryYearsAtResidence !== "" &&
      formData.primaryYearsAtResidence <= 1
    )
      return true;
    if (
      formData.primaryYearsAtResidence === "" &&
      formData.primaryMonthsAtResidence !== ""
    ) {
      if (Number(formData.primaryMonthsAtResidence) <= 11) return true;
    }
    if (
      formData.primaryMonthsAtResidence === "" &&
      formData.primaryYearsAtResidence !== ""
    ) {
      if (Number(formData.primaryYearsAtResidence) >= 2) return false;
    }
    if (
      formData.primaryMonthsAtResidence === "" ||
      formData.primaryYearsAtResidence === ""
    )
      return false;
  };
  const requirePreviousResidence = needPrevRes(); //@ Primary residence for < 1 year
  /* May need to have validation on click next, to endsure this is checked... */

  return (
    <>
      <CardContainer>
        <div>
          {/* <InfoBubble>
            <p>
              Let’s keep going to get you on the path to achieve your financial
              goals.
            </p>
          </InfoBubble> */}
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">
              Home is where the heart is. Tell us about yours.
            </h3>
            {/*   <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p> */}
          </div>
          <hr />
          <Form className="applyForm" onSubmit={handleSubmit} noValidate>
            <ResidenceForm
              residenceType="primary"
              handleChange={handleChange}
              formData={formData}
              handleTimeLived={handleTimeLived}
              validated={validated}
              status={status}
            />
            {requirePreviousResidence && (
              <>
                <hr className="mv-24" />
                <h3 className="titleBlock--title">
                  Where did you live before that?
                </h3>
                <ResidenceForm
                  residenceType="previous"
                  handleChange={handleChange}
                  formData={formData}
                  handleTimeLived={handleTimeLived}
                  validated={validated}
                  status={status}
                />
              </>
            )}
            <WideBtn
              disabled={status.loading}
              style={{ marginTop: "8px" }}
              id="submitResidence"
            >
              {status.loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                `Next`
              )}
            </WideBtn>
            {status.error && <p className="error">{status.error}</p>}
            <TextButton
              disabled={status.loading}
              onClick={() => {
                navForm("back");
              }}
            >
              Back
            </TextButton>
          </Form>
        </div>
      </CardContainer>
    </>
  );
}
